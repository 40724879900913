import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TableHeader from "../Common/TableComponent/TableHeader";
import TablesRow from "../Common/TableComponent/TablesRow";
import { Loading } from "../Common/OtherElements/Loading";
import { paginationPropertyExpenseReport } from "../../services/ReportsService";
import { TableDataStatusError } from "../Common/OtherElements/TableDataStatusError";
import { handleErrors } from "../../utils/errorHandler";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from "../Common/TableComponent/Pagination";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { fetchSWKProperty } from "../../services/SWKPropertService";
import AsyncSelect from "react-select/async";
import allImages from "../../assets/images-import";
import * as XLSX from "xlsx";
import { usePageLevelAccess } from "../../hooks/usePageLevelAccess";
import { fetchExpnature } from "../../services/expenseNatureService";
export const ManagePropertyExpenseReport = () => {
  const [entriesPerPage, setEntriesPerPage] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [properties, setProperties] = useState([]);
  const [propertieslist, setPropertieslist] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [pageAccessDetails, setPageAccessDetails] = useState([]);
  const [downloadBtnDisable, setDownloadBtnDisable] = useState(false);
  const PageLevelAccessurl = "reports/property-expense-report";
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [expenseNature, setExpenseNature] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.viewAccess) {
        navigate("/404-error-page");
      } else {
        setPageAccessDetails(pageAccessData);
      }
    } else {
      console.log("No page access details found");
    }
  }, [pageAccessData, navigate]);
  useEffect(() => {
    const getProperties = async () => {
      try {
        const result = await fetchSWKProperty();
        setPropertieslist(result);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };
    getProperties();
  }, []);

  const loadPropertyOptions = (inputValue, callback) => {
    const filteredProperties = propertieslist.filter((property) =>
      property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredProperties.map((property) => ({
          label: property.propertyName,
          value: property.propertyGuid,
        }))
      );
    }, 1000);
  };

  const searchInputRef = useRef(null);
  const fetchProperties = useCallback(async () => {
    setLoading(true);
    const formData = {
      pageSize: entriesPerPage,
      pageNo: currentPage,
      fromDate,
      toDate,
      sParam: selectedProperty,
      expNature: selectedProduct,
    };
    try {
      const response = await paginationPropertyExpenseReport(formData);
      console.log("response", response.data.result);
      const { data } = response;
      setProperties(data.result);
      setTotalCount(data.result[0]?.totalCount || 0);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }, [
    entriesPerPage,
    currentPage,
    fromDate,
    toDate,
    selectedProperty,
    selectedProduct,
  ]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  const handleDownloadReport = async () => {
    const formData = {
      pageSize: totalCount,
      pageNo: 1,
      fromDate,
      toDate,
      sParam: selectedProperty,
      expNature: selectedProduct,
    };

    try {
      setDownloadBtnDisable(true);
      const response = await paginationPropertyExpenseReport(formData);
      const { data } = response;
      const rentReports = data.result;
      setDownloadBtnDisable(false);

      const excelData = rentReports.map((item, index) => ({
        "#": index + 1,
        "Property Name": item.propertyName,
        "Flat Name": item.flatName,
        "Flat Number": item.flatNo,
        "Nature of Expense": item.natureOfExpense,
        Amount: item.amount,
        Comments: item.comments,
        "Approved By": item.approvedBy,
        "Expense Date": formatDate(item.expenseDate),
        Status: item.status,
        "Added By": item.addedBy,
        "Added On": formatDate(item.addedOn),
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        "Property Expense Report"
      );

      XLSX.writeFile(workbook, "Property_expense_Reports.xlsx");
    } catch (error) {
      handleErrors(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const totalPages = Math.ceil(totalCount / entriesPerPage);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    const getProperties = async () => {
      try {
        const response = await fetchExpnature();
        setExpenseNature(Array.isArray(response.result) ? response.result : []);
      } catch (error) {
        console.error("Error fetching properties:", error);
        setExpenseNature([]);
      }
    };
    getProperties();
  }, []);
  const loadExpenseOptions = (inputValue, callback) => {
    const filteredProperties = expenseNature.filter((property) =>
      property.expenseNatureTitle
        .toLowerCase()
        .includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredProperties.map((property) => ({
          label: property.expenseNatureTitle,
          value: property.expenseNatureTitle,
        }))
      );
    }, 1000);
  };
  return (
    <>
      <style>
        {`
                   .table>:not(caption)>*>* {
                      padding: .75rem 0.5rem !important;
                    }
                    .ri-pencil-fill:before {
                      display:none;
                    }
                    .ri-delete-bin-6-line:before {
                      display:none;
                    }
                    table td:nth-child(10){ display:none;} 
                `}
      </style>
      {pageAccessDetails.viewAccess ? (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card mt-xxl-n5">
              <div className="card-header responsive-filter-type justify-content-between">
                <h5 className="mb-sm-2 mt-sm-2">Property Expense Report</h5>
                {pageAccessDetails.downloadAccess ? (
                  <button
                    className="btn btn-download"
                    onClick={handleDownloadReport}
                    disabled={downloadBtnDisable}
                  >
                    <img
                      src={allImages.Excel}
                      alt="Property Expense report excel icon"
                      width="24px"
                    />{" "}
                    <span>
                      {downloadBtnDisable ? "Exporting..." : "Export To Excel"}
                    </span>
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="card-body manage-amenity-master-card-body">
                <div className="responsive-filter-type mb-3">
                  <div className="entries-dropdownx">
                    <label htmlFor="entriesPerPage" className="form-label me-2">
                      Show entries:
                    </label>
                    <select
                      className="form-select"
                      id="entriesPerPage"
                      value={entriesPerPage}
                      onChange={handleEntriesPerPageChange}
                    >
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>
                  </div>
                  <div className="date-filters">
                    <label htmlFor="fromDate" className="form-label me-2">
                      From Date:
                    </label>
                    <Flatpickr
                      id="fromDate"
                      className="form-control"
                      placeholder="Select From Date"
                      value={fromDate}
                      onChange={([date]) => setFromDate(date)}
                      options={{
                        dateFormat: "Y-m-d",
                        monthSelectorType: "static",
                      }}
                    />
                  </div>
                  <div className="date-filters">
                    <label htmlFor="toDate" className="form-label me-2">
                      To Date:
                    </label>
                    <Flatpickr
                      id="toDate"
                      className="form-control"
                      placeholder="Select To Date"
                      value={toDate}
                      onChange={([date]) => setToDate(date)}
                      options={{
                        dateFormat: "Y-m-d",
                        monthSelectorType: "static",
                      }}
                    />
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="search-input">
                      <label htmlFor="search" className="form-label me-2">
                        Nature of Expense:
                      </label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadExpenseOptions}
                        defaultOptions={(expenseNature || []).map(
                          (property) => ({
                            label: property.expenseNatureTitle,
                            value: property.expenseNatureTitle,
                          })
                        )}
                        onChange={(selectedOption) =>
                          setSelectedProduct(selectedOption?.label || "")
                        }
                        value={
                          selectedProduct
                            ? {
                                label: selectedProduct,
                                value: expenseNature.find(
                                  (property) =>
                                    property.expenseNatureTitle ===
                                    selectedProduct
                                )?.expenseNatureTitle,
                              }
                            : null
                        }
                        isClearable
                        placeholder="Select Expense Nature"
                      />
                    </div>
                  </div>
                  <div className="search-input">
                    <label htmlFor="propertyName" className="form-label">
                      Property Name
                    </label>
                    <AsyncSelect
                      cacheOptions
                      loadOptions={loadPropertyOptions}
                      defaultOptions={propertieslist.map((property) => ({
                        label: property.propertyName,
                        value: property.propertyGuid,
                      }))}
                      onChange={(selectedOption) =>
                        setSelectedProperty(selectedOption?.label || "")
                      }
                      value={
                        selectedProperty
                          ? {
                              label: selectedProperty,
                              value: propertieslist.find(
                                (property) =>
                                  property.propertyName === selectedProperty
                              )?.propertyGuid,
                            }
                          : null
                      }
                      isClearable
                      placeholder="Select Property Name"
                    />
                  </div>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle table-bordered">
                      <TableHeader
                        columns={[
                          "#",
                          "Property",
                          "Nature of Expense",
                          "Amount",
                          "Expense Date",
                          "Comments",
                          "Approved By",
                          "Added By",
                          "Added On",
                        ]}
                      />
                      <tbody className="manage-amenity-master-table-values p-3">
                        {properties.length > 0 ? (
                          properties.map((item, index) => (
                            <TablesRow
                              key={item.id}
                              rowData={{
                                id:
                                  (currentPage - 1) * entriesPerPage +
                                  index +
                                  1,
                                property: (
                                  <>
                                    <span>
                                      <Link
                                        to={`/property/detail/${item.propertyGuid}`}
                                      >
                                        {item.propertyName}
                                      </Link>
                                    </span>
                                    <br />
                                    <span>
                                      <label>Flat: </label>
                                      {item.flatName}({item.flatNo})
                                    </span>
                                  </>
                                ),
                                natureofexpense: item.natureOfExpense || "-",
                                amount: `Rs. ${item.amount}`,
                                expensedate:
                                  formatDate(item.expenseDate) || "-",
                                comments: item.comments || "-",
                                approvedby: item.approvedBy || "-",
                                addedby: item.addedBy || "-",
                                addedon: formatDate(item.addedOn) || "-",
                              }}
                              columns={[
                                "id",
                                "property",
                                "natureofexpense",
                                "amount",
                                "expensedate",
                                "comments",
                                "approvedby",
                                "addedby",
                                "addedon",
                              ]}
                              hideIcons={false}
                              showIcons={false}
                              onEdit={() => {
                                navigate(`update/${item.id}`);
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              }}
                              pageLevelAccessData={pageAccessDetails}
                            />
                          ))
                        ) : (
                          <TableDataStatusError colspan="10" />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  totalEntries={totalCount}
                  entriesPerPage={entriesPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
