import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const createExpnature = async (expnatureData) => {
  const response = await axiosInstance.post(
    "masters/expense-nature",
    expnatureData,
    {
      headers,
    }
  );
  return response.data;
};

export const fetchExpnature = async () => {
  const response = await axiosInstance.get("masters/expense-nature", {
    headers,
  });
  return response.data;
};

export const deleteExpnature = async (natureId) => {
  await axiosInstance.delete(`/masters/expense-nature/${natureId}`, {
    headers,
  });
};

export const fetchExpnatureeById = async (id) => {
  const response = await axiosInstance.get(`masters/expense-nature/${id}`, {
    headers,
  });
  return response.data.result;
};

export const updateExpnature = async (expenseNatureData) => {
  const response = await axiosInstance.put(
    "masters/expense-nature",
    expenseNatureData,
    {
      headers,
    }
  );
  return response.data;
};
