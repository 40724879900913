import React, { useState, useEffect, useCallback } from "react";
import {
  createProduct,
  updateProduct,
  fetchProductById,
} from "../../../services/ProductService";
import { validateProduct } from "../../../utils/validation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from "../../../utils/errorHandler";
import ComponentHeader from "../../Common/OtherElements/ComponentHeader";

export const AddProduct = ({
  editMode = false,
  initialData = {},
  onSuccess,
  setSelectedPageGroup,
  setEditMode,
}) => {
  const [formData, setFormData] = useState({
    productName: "",
    productType: "",
  });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const resetForm = useCallback(() => {
    setFormData({ productName: "", productType: "" });
    setErrors({});
    setApiError("");
    setSelectedPageGroup(null);
    setEditMode(false);
  }, [setSelectedPageGroup, setEditMode]);

  useEffect(() => {
    if (editMode && initialData.id) {
      const fetchCity = async () => {
        try {
          const data = await fetchProductById(initialData.id);
          setFormData({
            productName: data.productName || "",
            productType: data.productType || "",
          });
        } catch (error) {
          handleErrors(error);
        }
      };
      fetchCity();
    } else {
      resetForm();
    }
  }, [editMode, initialData, resetForm]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { valid, errors: validationErrors } = validateProduct(formData);
      setErrors(validationErrors);

      if (valid) {
        setApiError("");
        try {
          if (editMode) {
            setIsButtonDisabled(true);
            await updateProduct({ ...formData, id: initialData.id });
            toast.success("Product updated successfully!");
          } else {
            setIsButtonDisabled(true);
            await createProduct(formData);
            toast.success("Product added successfully!");
          }
          resetForm();
          if (onSuccess) onSuccess();
        } catch (error) {
          const errorData = error.response?.data;
          if (errorData?.errors) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              ...errorData.errors,
            }));
          } else {
            setApiError("An unexpected error occurred.");
          }
        }
        setIsButtonDisabled(false);
      }
    },
    [formData, editMode, initialData, onSuccess, resetForm]
  );

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "productType") {
      if (value === "Tagged") {
        setFormData({
          ...formData,
          [name]: value,
          quantity: 1,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
          quantity: formData.quantity || 1,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  return (
    <>
      <ComponentHeader title="Product Master" />

      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">
                {editMode ? "Update Product" : "Add Product"}
              </h5>
            </div>
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="productName" className="form-label">
                        Product Name <span className="required-field">*</span>
                      </label>
                      <input
                        type="text"
                        name="productName"
                        value={formData.productName}
                        placeholder="Enter Product Name"
                        onChange={handleInputChange}
                        className={`form-control ${
                          errors.productName ? "is-invalid" : ""
                        }`}
                      />
                      {errors.productName && (
                        <div className="invalid-feedback">
                          {errors.productName}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="productType" className="form-label">
                        Type of Asset
                      </label>

                      <select
                        name="productType"
                        value={formData.productType}
                        onChange={handleChange}
                        className={`form-select ${
                          errors.productType ? "is-invalid" : ""
                        }`}
                      >
                        <option value="">Assets Type</option>
                        <option value="Tagged">Tagged</option>
                        <option value="Non-Tagged">Non-Tagged</option>
                      </select>
                      {errors.productType && (
                        <p className="text-danger">{errors.productType}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="pt-4">
                      <button
                        type="submit"
                        className="btn btn-secondary pt-1 pb-1 p-3"
                        disabled={isButtonDisabled}
                      >
                        {isButtonDisabled
                          ? editMode
                            ? "Updating"
                            : "Saving"
                          : editMode
                          ? "Update"
                          : "Save"}
                      </button>
                      {editMode && (
                        <button
                          type="button"
                          onClick={resetForm}
                          className="btn btn-danger ms-1 pt-1 pb-1 p-3"
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {apiError && (
                  <div className="alert alert-danger">{apiError}</div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
