import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const paginationFlatReport = async (formData) => {
  const response = await axiosInstance.post("reports/flat", formData, {
    headers,
  });
  return response;
};
export const fetchLocations = async () => {
  const response = await axiosInstance.get("location/all", { headers });
  return response.data;
};

export const paginationPropertyListingReport = async (formData) => {
  const response = await axiosInstance.post(
    "reports/property-listing",
    formData,
    { headers }
  );
  return response;
};

export const paginationRentReport = async (formData) => {
  const response = await axiosInstance.post("reports/rent-report", formData, {
    headers,
  });
  return response;
};
export const paginationConsolidatedReport = async (formData) => {
  const response = await axiosInstance.post(
    "reports/consolidated-report",
    formData,
    {
      headers,
    }
  );
  return response;
};

export const paginationMaintainenceReport = async (formData) => {
  const response = await axiosInstance.post(
    "reports/maintenance-report",
    formData,
    { headers }
  );
  return response;
};

export const paginationUtilityReport = async (formData) => {
  const response = await axiosInstance.post(
    "reports/utility-report",
    formData,
    { headers }
  );
  return response;
};

export const paginationOthersReport = async (formData) => {
  const response = await axiosInstance.post("reports/other-report", formData, {
    headers,
  });
  return response;
};

export const paginationPropertyExpenseReport = async (formData) => {
  const response = await axiosInstance.post(
    "reports/property-expense-report",
    formData,
    { headers }
  );
  return response;
};

export const paginationUpcomingVacationReport = async (formData) => {
  const response = await axiosInstance.post(
    "reports/flat-vacations-report",
    formData,
    { headers }
  );
  return response;
};

export const paginationMovedOutReport = async (formData) => {
  const response = await axiosInstance.post(
    "reports/moved-out-report",
    formData,
    { headers }
  );
  return response;
};
