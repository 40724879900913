import React, { useState, useEffect, useCallback } from "react";
import "react-toastify/dist/ReactToastify.css";
import { validateFlatFields } from "../../../utils/validation";
import { useParams } from "react-router-dom";
import { fetchSWKPropertyByGuid } from "../../../services/SWKPropertService";
import { toast } from "react-toastify";
import {
  createFlatMaster,
  fetchFlatById,
  updateFlat,
} from "../../../services/FlatMasterService";
import { FormSection1 } from "./FormSection/FormSection1";
import { FormSection2 } from "./FormSection/FormSection2";
import { FormSection3 } from "./FormSection/FormSection3";
import {
  Section1InputFields,
  Section2InputFields,
  Section3InputFields,
} from "./FormSection/FormFieldsInfo";
import { handleErrors } from "../../../utils/errorHandler";
import { Link } from "react-router-dom";
import { usePageLevelAccess } from "../../../hooks/usePageLevelAccess";
import { useNavigate } from "react-router-dom";

const generateSlug = (flatName) => {
  return flatName
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/^-+|-+$/g, "");
};

export const AddFlat = () => {
  const { id } = useParams();
  const { flatid } = useParams();
  const [propertySeries, setpropertySeries] = useState(0);
  const [propertyguid, setPropertyGuid] = useState(id);
  const { inputfields } = Section1InputFields(propertySeries, propertyguid);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [propertyAllDetails, setPropertyAllDetails] = useState([]);
  const [propertyDetailAll, setPropertyDetailAll] = useState([]);
  const [clearKey, setClearKey] = useState(0);
  const [PageLevelAccessurl, setPageLevelAccessurl] = useState();
  useEffect(() => {
    if (flatid) {
      setPageLevelAccessurl("/property/detail/update-flat/:flatid");
    } else {
      setPageLevelAccessurl("/property/detail/add-flat/:id");
    }
  }, [flatid]);
  const navigate = useNavigate();
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    if (pageAccessData) {
      if (flatid) {
        if (!pageAccessData.editAccess) {
          navigate("/404-error-page");
        } else {
          return;
        }
      } else {
        if (!pageAccessData.addAccess) {
          navigate("/404-error-page");
        } else {
          return;
        }
      }
    } else {
      console.log("No page access details found");
    }
  });

  const [formData, setFormData] = useState({
    flatNo: "",
    floorNo: "",
    flatSeries: "",
    flatName: "",
    flatUrl: "",
    propertyManagedBy: "",
    preferredTenants: "",
    parking: "",
    bhkType: "",
    furnished: "",
    flatDesc: "",
    ownerGuid: "",
    operatingSince: "",
    flatSize: "",
    stayType: "",
    noOfBalcony: "",
    noOfBedRooms: "",
    noOfBathRooms: "",
    noOfPersonAllowed: "",
    availableFrom: propertyAllDetails?.rentStatDate || "",
    handoverDate: propertyAllDetails?.handOverDate || "",
    renewalDate: propertyAllDetails?.renewalDate || "",
    extraAllowed: null,
    petsAllowed: false,
    eMeterNo: "",
    wMeterNo: "",
    waterBill: "",
    facing: "",
    tags: "",
    amenityIds: [],
    pageTitle: "",
    metaKeys: "",
    metaDesc: "",
    flatPricing: {
      monthlyRentST: "",
      monthlyRentSTDeposit: "",
      monthlyRentLT: "",
      monthlyRentLTDeposit: "",
      dailyRent: "",
      dailyRentDeposit: "",
      ownerRent: "",
      fixedWaterBill: "",
      flatMaintenance: "",
      parkingCharges: "",
      otherCharges: "",
      ownerWaterCharge: "",
    },
    propertyGuid: id,
  });
  console.log(propertyAllDetails, "ygvbyvb");
  useEffect(() => {
    if (propertyAllDetails) {
      setFormData((prev) => ({
        ...prev,
        availableFrom: propertyAllDetails?.rentStatDate || "",
        handoverDate: propertyAllDetails?.handOverDate || "",
        renewalDate: propertyAllDetails?.renewalDate || "",
      }));
    }
  }, [propertyAllDetails]);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      if (flatid) {
        try {
          const flatData = await fetchFlatById(flatid);
          const flatPropertyGuid = flatData.propertyGuid;
          setPropertyGuid(flatData.propertyGuid);
          setFormData((prevData) => ({
            ...prevData,
            ...flatData,
            flatUrl: generateSlug(flatData.flatName),
            availableFrom: formatDate(flatData.availableFrom),
            handoverDate: formatDate(flatData.handoverDate),
            renewalDate: formatDate(flatData.renewalDate),
            noOfBalcony: String(flatData.noOfBalcony),
            noOfBedRooms: String(flatData.noOfBedRooms),
          }));

          const propertyDetails = await fetchSWKPropertyByGuid(
            flatPropertyGuid
          );

          setPropertyDetailAll(propertyDetails);
          setpropertySeries(propertyDetails.propertySeries);
        } catch (error) {
          handleErrors(error);
        }
      }
    };

    fetchData();
  }, [flatid, propertySeries]);

  const fetchPropertyDetails = useCallback(async () => {
    if (!propertyguid) return;
    try {
      const propertyDetails = await fetchSWKPropertyByGuid(propertyguid);
      setPropertyAllDetails(propertyDetails);
      setpropertySeries(propertyDetails.propertySeries);
    } catch (error) {
      handleErrors(error);
    }
  }, [propertyguid]);

  useEffect(() => {
    fetchPropertyDetails();
  }, [fetchPropertyDetails]);

  const handleCheckboxChangeAmenity = (field, option) => {
    const currentSelections = formData[field] ? [...formData[field]] : [];

    if (currentSelections.includes(option)) {
      const updatedSelections = currentSelections.filter(
        (item) => item !== option
      );
      setFormData({
        ...formData,
        [field]: updatedSelections,
      });
    } else {
      currentSelections.push(option);
      setFormData({
        ...formData,
        [field]: currentSelections,
      });
    }
  };

  const handleCheckboxChange = (field, option) => {
    let currentSelections = formData[field] ? formData[field] : "";

    if (currentSelections.includes(option)) {
      const options = currentSelections.split(", ");
      const index = options.indexOf(option);
      if (index !== -1) {
        options.splice(index, 1);
      }
      currentSelections = options.join(", ");
    } else {
      if (currentSelections) {
        currentSelections += ", ";
      }
      currentSelections += option;
    }

    setFormData({
      ...formData,
      [field]: currentSelections,
    });
  };

  const preferredTenantsdata = ["Family", "Bachelor", "Couples"];
  const parkingOptionsdata = ["Bike", "Car"];
  const stayType = ["Daily", "Short Term", "Long Term"];
  const tags = ["New Arrival", "Fast Selling"];

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name in formData.flatPricing) {
      setFormData((prevData) => ({
        ...prevData,
        flatPricing: {
          ...prevData.flatPricing,
          [name]: value,
        },
      }));
    } else {
      let updatedFormData = { ...formData, [name]: value };

      if (name === "petsAllowed") {
        updatedFormData = { ...updatedFormData, [name]: value === "true" };
      } else if (name === "flatName") {
        updatedFormData = { ...updatedFormData, flatUrl: generateSlug(value) };
      }

      setFormData(updatedFormData);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateFlatFields(formData);
    console.log("validationErrors", validationErrors);
    if (Object.keys(validationErrors).length === 0) {
      setIsButtonDisabled(true);

      // Ensure these fields are integers
      const updatedFormData = {
        ...formData,
        extraAllowed: Number(formData.extraAllowed) || 0,
        noOfBalcony: Number(formData.noOfBalcony) || 0,
        noOfBedRooms: Number(formData.noOfBedRooms) || 0,
        noOfBathRooms: Number(formData.noOfBathRooms) || 0,
        flatMaintenance: Number(formData.flatMaintenance) || 0,
        parkingCharges: Number(formData.parkingCharges) || 0,
        otherCharges: Number(formData.otherCharges) || 0,
      };

      if (flatid) {
        try {
          await updateFlat(updatedFormData);
          toast.success("Flat updated successfully!");
          setIsButtonDisabled(false);
        } catch (error) {
          handleErrors(error);
          setIsButtonDisabled(false);
        }
      } else {
        createFlatMaster(updatedFormData)
          .then((response) => {
            toast.success("Flat created successfully!");
            setIsButtonDisabled(false);
            setClearKey((prevKey) => prevKey + 1);

            setFormData({
              flatNo: "",
              floorNo: "",
              flatSeries: "",
              flatName: "",
              flatUrl: "",
              propertyManagedBy: "",
              preferredTenants: "",
              parking: "",
              bhkType: "",
              furnished: "",
              flatDesc: "",
              ownerGuid: "",
              operatingSince: "",
              flatSize: "",
              stayType: "",
              tags: "",
              noOfBalcony: 0,
              noOfBedRooms: 0,
              noOfBathRooms: 0,
              noOfPersonAllowed: "",
              availableFrom: propertyAllDetails?.rentStatDate || "",
              handoverDate: propertyAllDetails?.handOverDate || "",
              renewalDate: propertyAllDetails?.renewalDate || "",
              extraAllowed: 0,
              petsAllowed: false,
              eMeterNo: "",
              wMeterNo: "",
              waterBill: "",
              facing: "",
              amenityIds: [],
              pageTitle: "",
              metaKeys: "",
              metaDesc: "",
              flatPricing: {
                monthlyRentST: "",
                monthlyRentSTDeposit: "",
                monthlyRentLT: "",
                monthlyRentLTDeposit: "",
                dailyRent: "",
                dailyRentDeposit: "",
                ownerRent: "",
                fixedWaterBill: "",
                flatMaintenance: 0,
                parkingCharges: 0,
                otherCharges: 0,
                ownerWaterCharge: 0,
              },
              propertyGuid: id,
            });
          })
          .catch((error) => {
            handleErrors(error);
            setIsButtonDisabled(false);
          });
      }
    } else {
      setErrors(validationErrors);
      setIsButtonDisabled(false);
    }
  };

  const heading = flatid ? "Update Flat" : "Add Flat";
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">{heading}</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="ri-home-2-fill"></i>
                  </Link>
                </li>
                {flatid ? (
                  <>
                    <li className="breadcrumb-item">
                      <Link to="/property">Manage Properties</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`/property/detail/${propertyguid}`}>
                        Property Detail
                      </Link>
                    </li>
                    <li className="breadcrumb-item">Update Flat-{flatid}</li>
                  </>
                ) : (
                  <>
                    <li className="breadcrumb-item">
                      <Link to="/property">Manage Properties</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`/property/detail/${id}`}>Property Detail</Link>
                    </li>
                    <li className="breadcrumb-item">Add Flat</li>
                  </>
                )}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <form method="POST" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xxl-8">
            <div className="card mt-xxl-n5">
              <div className="card-header">
                <h5 className="mb-sm-1 mt-sm-1">Flat Details </h5>
              </div>
              <div className="card-body p-4">
                <div className="row">
                  {inputfields.map((field) => (
                    <FormSection1
                      key={field.name}
                      label={field.label}
                      labelStar={field.labelStar}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      error={errors[field.name]}
                      type={field.type}
                      options={field.options}
                      preferredTenantsdata={preferredTenantsdata}
                      parkingOptionsdata={parkingOptionsdata}
                      stayType={stayType}
                      tags={tags}
                      formData={formData}
                      propertyAllDetails={propertyAllDetails}
                      propertyguid={propertyguid}
                      propertyDetailAll={propertyDetailAll}
                      propertySeries={propertySeries}
                      setFormData={setFormData}
                      clearKey={clearKey}
                      handleCheckboxChangeAmenity={handleCheckboxChangeAmenity}
                      handleCheckboxChange={handleCheckboxChange}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xxl-4">
            <div className="card mt-xxl-n5">
              <div className="card-header">
                <h5 className="mb-sm-1 mt-sm-1">Pricing</h5>
              </div>
              <div className="card-body p-4">
                <div className="row">
                  {Section2InputFields.map((field) => (
                    <FormSection2
                      key={field.name}
                      label={field.label}
                      labelStar={field.labelStar}
                      name={field.name}
                      value={formData.flatPricing[field.name]}
                      onChange={handleChange}
                      error={errors[field.name]}
                      type={field.type}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="card mt-xxl-n5">
              <div className="card-header">
                <h5 className="mb-sm-1 mt-sm-1">Meta Details</h5>
              </div>
              <div className="card-body p-4">
                <div className="row">
                  {Section3InputFields.map((field) => (
                    <FormSection3
                      key={field.name}
                      label={field.label}
                      labelStar={field.labelStar}
                      name={field.name}
                      value={formData[field.name]}
                      onChange={handleChange}
                      error={errors[field.name]}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button
            className="btn btn-secondary"
            type="submit"
            disabled={isButtonDisabled}
          >
            {isButtonDisabled
              ? flatid
                ? "Updating"
                : "Saving"
              : flatid
              ? "Update"
              : "Save"}
          </button>
        </div>
      </form>
    </>
  );
};
