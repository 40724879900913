import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const fetchPropertyDocuments = async (documentId) => {
  const response = await axiosInstance.get(
    `onboarding/owner/document/${documentId}`,
    { headers }
  );
  return response.data.result;
};
export const uploadDocument = async (documentData) => {
  try {
    const formData = new FormData();

    formData.append("propertyGuid", documentData.propertyGuid);
    formData.append("DocTitle", documentData.docTitle);
    formData.append("Remark", documentData.remark);

    if (documentData.attachment) {
      formData.append("Attachment", documentData.attachment);
    }

    const response = await axiosInstance.post(
      "/properties/document/add-document",
      formData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to upload document."
    );
  }
};

export const getPropertyDocuments = async (propertyGuid) => {
  return await axiosInstance.get(
    `/properties/document/add-document/all/${propertyGuid}`,
    {
      headers,
    }
  );
};
export const deletePropertyDocument = async (galleryId) => {
  return await axiosInstance.delete(
    `/properties/document/add-document/${galleryId}`,
    {
      headers,
    }
  );
};
