import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  fetchSWKPropertyById,
  updateSWKProperty,
} from "../../../services/SWKPropertService";
import { fetchCities } from "../../../services/cityService";
import { fetchLocations } from "../../../services/locationService";
import { fetchPropertyTypes } from "../../../services/propertyTypeService";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { Loading } from "../../Common/OtherElements/Loading";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import { handleErrors } from "../../../utils/errorHandler";
import { Link } from "react-router-dom";
import { MapSection } from "./MapSection";
import { validateFields } from "../../../utils/validation";
import { usePageLevelAccess } from "../../../hooks/usePageLevelAccess";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { getPropertyOwners } from "../../../services/propertyOwnerService";
export const UpdateSWRProperty = () => {
  const { id } = useParams();
  const [isPowerBackupDropdownOpen, setPowerBackupDropdownOpen] =
    useState(false);
  const [isWaterSourceDropdownOpen, setWaterSourceDropdownOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showMapModal, setShowMapModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [owners, setOwners] = useState([]);
  const [selectedOwners, setSelectedOwners] = useState([]);
  const [formData, setFormData] = useState({
    city: "",
    propertyLocation: "",
    propertyType: "",
    propertySeries: "",
    propertyName: "",
    noOfFlats: "",
    noOfFloors: "",
    buldingAge: "",
    powerBackup: "",
    waterSource: "",
    liftAvailble: "",
    eBillGenerateDate: "",
    eBillDueDate: "",
    propertyLat: "",
    propertyLong: "",
    handOverDate: "",
    rentStatDate: "",
    renewalDate: "",
    propertyAddress: "",
    mapUrl: "",
    propertyNote: "",
    agreementPeriod: "",
    agreementDate: "",
    bufferPeriod: "",
    hikePercentage: "",
    hikeAfter: "",
    opsNote: "",
    amcBy: "",
    owners: [],
  });
  const [cities, setCities] = useState([]);
  const [locations, setLocations] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const PageLevelAccessurl = "property/update/:id";
  const navigate = useNavigate();
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.editAccess && !pageAccessData.viewAccess) {
        navigate("/404-error-page");
      } else {
        return;
      }
    } else {
      console.log("No page access details found");
    }
  });

  useEffect(() => {
    const loadCities = async () => {
      setLoading(true);
      try {
        const cityData = await fetchCities();
        setCities(cityData.result || []);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    };
    loadCities();
  }, []);

  useEffect(() => {
    const fetchSWRPropertyData = async () => {
      try {
        const SWRPropertyData = await fetchSWKPropertyById(id);

        if (!SWRPropertyData) {
          console.error("No property data found");
          return;
        }
        setSelectedCity(SWRPropertyData.city);
        setFormData((prevState) => ({
          ...prevState,
          city: SWRPropertyData.city || "",
          propertyLocation: SWRPropertyData.propertyLocation || "",
          propertyType: SWRPropertyData.propertyType || "",
          propertySeries: SWRPropertyData.propertySeries || "",
          propertyName: SWRPropertyData.propertyName || "",
          noOfFlats: SWRPropertyData.noOfFlats || "",
          noOfFloors: SWRPropertyData.noOfFloors || "",
          buldingAge: SWRPropertyData.buldingAge || "",
          powerBackup: SWRPropertyData.powerBackup || "",
          waterSource: SWRPropertyData.waterSource || "",
          liftAvailble: SWRPropertyData.liftAvailble || "",
          eBillGenerateDate: SWRPropertyData.eBillGenerateDate || "",
          eBillDueDate: SWRPropertyData.eBillDueDate || "",
          propertyLat: SWRPropertyData.propertyLat || "",
          propertyNote: SWRPropertyData.propertyNote || "",
          propertyLong: SWRPropertyData.propertyLong || "",
          handOverDate: SWRPropertyData.handOverDate || "",
          rentStatDate: SWRPropertyData.rentStatDate || "",
          renewalDate: SWRPropertyData.renewalDate || "",
          propertyAddress: SWRPropertyData.propertyAddress || "",
          mapUrl: SWRPropertyData.mapUrl || "",
          agreementPeriod: SWRPropertyData.agreementPeriod || "",
          agreementDate: SWRPropertyData.agreementDate || "",
          bufferPeriod: SWRPropertyData.bufferPeriod || "",
          hikePercentage: SWRPropertyData.hikePercentage || "",
          hikeAfter: SWRPropertyData.hikeAfter || "",
          opsNote: SWRPropertyData.opsNote || "",
          amcBy: SWRPropertyData.amcBy || "",
          owners: SWRPropertyData.owners || [],
        }));
        const response = await getPropertyOwners();
        const allOwners = response.data.result.map((owner) => ({
          id: owner.ownerGuid,
          ownerGuid: owner.ownerGuid,
          title: `${owner.firstName} ${owner.lastName} - ${owner.contactNo}`,
        }));
        setOwners(allOwners);
        const selectedOwnersList = allOwners.filter((owner) =>
          SWRPropertyData.owners.includes(owner.ownerGuid)
        );

        setSelectedOwners(selectedOwnersList);
        setFormData((prevState) => ({
          ...prevState,
          ...SWRPropertyData,
          owners: selectedOwnersList.map((o) => o.ownerGuid),
        }));
      } catch (error) {
        handleErrors(error);
      }
    };

    fetchSWRPropertyData();
  }, [id]);

  useEffect(() => {
    const loadLocations = async () => {
      if (selectedCity) {
        setLoading(true);
        try {
          const locationData = await fetchLocations();
          const filteredLocations = locationData.result.filter(
            (location) => location.cityName === selectedCity
          );
          setLocations(filteredLocations);
        } catch (error) {
          handleErrors(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLocations([]);
      }
    };
    loadLocations();
  }, [selectedCity]);

  useEffect(() => {
    const loadPropertyTypes = async () => {
      setLoading(true);
      try {
        const propertyTypeData = await fetchPropertyTypes();
        setPropertyTypes(propertyTypeData.result || []);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    };
    loadPropertyTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "city") {
      setSelectedCity(value);
    }
  };

  const handleMapModalClose = () => setShowMapModal(false);
  const handleMapModalOpen = () => setShowMapModal(true);

  const handleAddressSelection = (address) => {
    setFormData({ ...formData, propertyAddress: address });
    setShowMapModal(false);
  };

  const handleLatChange = (lat) => {
    setFormData((prevData) => ({ ...prevData, propertyLat: lat }));
  };

  const handleMapUrlChange = (address) => {
    setFormData((prevData) => ({ ...prevData, mapUrl: address }));
  };

  const handleLngChange = (lng) => {
    setFormData((prevData) => ({ ...prevData, propertyLong: lng }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const selectedOwnerIds = selectedOwners.map((owner) => owner.ownerGuid);
    const updatedData = {
      ...formData,
      owners: selectedOwnerIds,
    };
    const { valid, errors } = validateFields(updatedData);
    setErrors(errors);
    if (valid) {
      try {
        setIsButtonDisabled(true);
        await updateSWKProperty({ id, ...formData });
        toast.success("Property updated successfully!");
        setIsButtonDisabled(false);
      } catch (error) {
        handleErrors(error);
        setIsButtonDisabled(false);
      } finally {
        setLoading(false);
      }
    } else {
      setIsButtonDisabled(false);
      setLoading(false);
    }
  };

  const powerBackupOptions = ["DG", "UPS"];
  const waterSourceOptions = ["Kaveri", "Borewell"];

  const powerBackupDropdownRef = useRef(null);
  const waterSourceDropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        powerBackupDropdownRef.current &&
        !powerBackupDropdownRef.current.contains(event.target)
      ) {
        setPowerBackupDropdownOpen(false);
      }
      if (
        waterSourceDropdownRef.current &&
        !waterSourceDropdownRef.current.contains(event.target)
      ) {
        setWaterSourceDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isPowerBackupDropdownOpen, isWaterSourceDropdownOpen]);

  const handleCheckboxChange = (type, option) => {
    const currentValue = formData[type];

    if (currentValue.includes(option)) {
      const updatedValue = currentValue
        .split(", ")
        .filter((item) => item !== option)
        .join(", ");
      setFormData({
        ...formData,
        [type]: updatedValue,
      });
    } else {
      const updatedValue = currentValue ? `${currentValue}, ${option}` : option;
      setFormData({
        ...formData,
        [type]: updatedValue,
      });
    }
  };

  useEffect(() => {
    const fetchOwners = async () => {
      try {
        const response = await getPropertyOwners();
        const formattedOwners = response.data.result.map((owner) => ({
          id: owner.ownerGuid, // Ensure ownerGuid is used
          ownerGuid: owner.ownerGuid,
          title: `${owner.firstName} ${owner.lastName} - ${owner.contactNo}`,
        }));
        setOwners([...formattedOwners]);
      } catch (error) {
        console.error("Error fetching owners:", error);
      }
    };

    fetchOwners();
  }, []);
  //   const handleOwnerChange = (event, newValue) => {
  //     setSelectedOwners(newValue);
  //   };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Property</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="ri-home-2-fill"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/property">Manage Properties</Link>
                </li>
                <li className="breadcrumb-item">Update Property-{id}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">Update Property</h5>
            </div>
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="agreementPeriod" className="form-label">
                        Agrement Period{" "}
                        <span className="required-field">*</span>
                      </label>
                      <input
                        type="number"
                        name="agreementPeriod"
                        className={`form-control ${
                          errors.agreementPeriod ? "is-invalid" : ""
                        }`}
                        value={formData.agreementPeriod}
                        onChange={handleChange}
                        placeholder="Enter Agrement Period"
                      />
                      {errors.agreementPeriod && (
                        <div className="invalid-feedback">
                          {errors.agreementPeriod}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="bufferPeriod" className="form-label">
                        Buffer Period <span className="required-field">*</span>
                      </label>
                      <input
                        type="number"
                        name="bufferPeriod"
                        className={`form-control ${
                          errors.bufferPeriod ? "is-invalid" : ""
                        }`}
                        value={formData.bufferPeriod}
                        onChange={handleChange}
                        placeholder="Enter Buffer Period"
                      />
                      {errors.bufferPeriod && (
                        <div className="invalid-feedback">
                          {errors.bufferPeriod}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="hikePercentage" className="form-label">
                        Hike Percentage{" "}
                        <span className="required-field">*</span>
                      </label>
                      <input
                        type="number"
                        name="hikePercentage"
                        className={`form-control ${
                          errors.hikePercentage ? "is-invalid" : ""
                        }`}
                        value={formData.hikePercentage}
                        onChange={handleChange}
                        placeholder="Enter  Hike Percentage"
                      />
                      {errors.hikePercentage && (
                        <div className="invalid-feedback">
                          {errors.hikePercentage}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="hikeAfter" className="form-label">
                        Hike After <span className="required-field">*</span>
                      </label>
                      <input
                        type="number"
                        name="hikeAfter"
                        className={`form-control ${
                          errors.hikeAfter ? "is-invalid" : ""
                        }`}
                        value={formData.hikeAfter}
                        onChange={handleChange}
                        placeholder="Enter Hike After Period"
                      />
                      {errors.hikeAfter && (
                        <div className="invalid-feedback">
                          {errors.hikeAfter}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="agreementDate" className="form-label">
                        Agreement Date
                      </label>
                      <Flatpickr
                        className={`form-control ${
                          errors.agreementDate ? "is-invalid" : ""
                        }`}
                        value={
                          formData.agreementDate
                            ? new Date(formData.agreementDate)
                            : null
                        }
                        placeholder="Enter Agreement Date"
                        onChange={(date) =>
                          setFormData({
                            ...formData,
                            agreementDate: date.length
                              ? date[0].toISOString()
                              : "",
                          })
                        }
                        options={{
                          dateFormat: "d-M-Y",
                          monthSelectorType: "static",
                        }}
                      />
                      {errors.agreementDate && (
                        <div className="invalid-feedback">
                          {errors.agreementDate}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <label htmlFor="search" className="form-label me-2">
                      Property Managed By
                    </label>
                    <select
                      className="form-select"
                      value={formData.amcBy}
                      name="amcBy"
                      onChange={handleChange}
                    >
                      <option value="">Select Property Managed By</option>
                      <option value="Owner/Third Party">
                        Owner/Third Party
                      </option>
                      <option value="SoWeRent">SoWeRent</option>
                    </select>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="owners" className="form-label">
                        Property Owners
                      </label>
                      <Autocomplete
                        multiple
                        id="owners-checkbox-autocomplete"
                        options={owners}
                        limitTags={2}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.title}
                        value={selectedOwners}
                        onChange={(event, newValue) => {
                          setSelectedOwners(newValue);
                          setFormData((prevData) => ({
                            ...prevData,
                            owners: newValue.map((o) => o.ownerGuid),
                          }));
                        }}
                        renderOption={(props, option, { selected }) => {
                          const { key, ...optionProps } = props;
                          return (
                            <li key={key} {...optionProps}>
                              <Checkbox
                                checked={selected}
                                style={{ marginRight: 8 }}
                              />
                              {option.title}
                            </li>
                          );
                        }}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="city" className="form-label">
                        City <span className="required-field">*</span>
                      </label>
                      <select
                        name="city"
                        className={`form-select ${
                          errors.city ? "is-invalid" : ""
                        }`}
                        value={formData.city}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select City
                        </option>
                        {cities.map((city) => (
                          <option key={city.id} value={city.cityName}>
                            {city.cityName}
                          </option>
                        ))}
                      </select>
                      {errors.city && (
                        <div className="text-danger">{errors.city}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyLocation" className="form-label">
                        Property Location{" "}
                        <span className="required-field">*</span>
                      </label>
                      <select
                        name="propertyLocation"
                        className={`form-select ${
                          errors.propertyLocation ? "is-invalid" : ""
                        }`}
                        value={formData.propertyLocation}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Location
                        </option>
                        {locations.map((location) => (
                          <option
                            key={location.id}
                            value={location.locationName}
                          >
                            {location.locationName}
                          </option>
                        ))}
                      </select>
                      {errors.propertyLocation && (
                        <div className="text-danger">
                          {errors.propertyLocation}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyType" className="form-label">
                        Property Type <span className="required-field">*</span>
                      </label>
                      <select
                        name="propertyType"
                        className={`form-select ${
                          errors.propertyType ? "is-invalid" : ""
                        }`}
                        value={formData.propertyType}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select Property Type
                        </option>
                        {propertyTypes.map((type) => (
                          <option key={type.id} value={type.propertyType}>
                            {type.propertyType}
                          </option>
                        ))}
                      </select>
                      {errors.propertyType && (
                        <div className="text-danger">{errors.propertyType}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertySeries" className="form-label">
                        Property Series
                      </label>
                      <input
                        type="number"
                        name="propertySeries"
                        className="form-control"
                        value={formData.propertySeries}
                        onChange={handleChange}
                        placeholder="Enter Property Series"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyName" className="form-label">
                        Property Name <span className="required-field">*</span>
                      </label>
                      <input
                        type="text"
                        name="propertyName"
                        className={`form-control ${
                          errors.propertyName ? "is-invalid" : ""
                        }`}
                        value={formData.propertyName}
                        onChange={handleChange}
                      />
                      {errors.propertyName && (
                        <div className="text-danger">{errors.propertyName}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="noOfFlats" className="form-label">
                        No. of Flats <span className="required-field">*</span>
                      </label>
                      <input
                        type="number"
                        name="noOfFlats"
                        className={`form-control ${
                          errors.noOfFlats ? "is-invalid" : ""
                        }`}
                        value={formData.noOfFlats}
                        onChange={handleChange}
                      />
                      {errors.noOfFlats && (
                        <div className="text-danger">{errors.noOfFlats}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="noOfFloors" className="form-label">
                        No. of Floors <span className="required-field">*</span>
                      </label>
                      <input
                        type="number"
                        name="noOfFloors"
                        className={`form-control ${
                          errors.noOfFloors ? "is-invalid" : ""
                        }`}
                        value={formData.noOfFloors}
                        onChange={handleChange}
                      />
                      {errors.noOfFloors && (
                        <div className="text-danger">{errors.noOfFloors}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="buldingAge" className="form-label">
                        Building Age <span className="required-field">*</span>
                      </label>
                      <input
                        type="number"
                        name="buldingAge"
                        className={`form-control ${
                          errors.buldingAge ? "is-invalid" : ""
                        }`}
                        value={formData.buldingAge}
                        onChange={handleChange}
                      />
                      {errors.buldingAge && (
                        <div className="text-danger">{errors.buldingAge}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="powerBackup" className="form-label">
                        Power Backup
                      </label>
                      <FormControl
                        sx={{
                          m: 1,
                          width: "100%",
                        }}
                        margin="normal"
                        fullWidth
                      >
                        <Select
                          labelId="power-backup-checkbox-label"
                          className="form-select"
                          id="power-backup-checkbox"
                          multiple
                          value={
                            formData.powerBackup
                              ? formData.powerBackup.split(",")
                              : []
                          }
                          onChange={handleChange}
                          input={<OutlinedInput label="Power Backup" />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {powerBackupOptions.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                              onClick={(e) => {
                                if (e.target.tagName !== "INPUT") {
                                  handleCheckboxChange("powerBackup", option);
                                }
                              }}
                            >
                              <Checkbox
                                checked={formData.powerBackup.includes(option)}
                                onChange={() =>
                                  handleCheckboxChange("powerBackup", option)
                                }
                              />
                              <ListItemText primary={option} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="waterSource" className="form-label">
                        Water Source
                      </label>
                      <FormControl
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        sx={{
                          m: 1,
                          width: "100%",
                        }}
                      >
                        <Select
                          labelId="water-source-checkbox-label"
                          className="form-select"
                          id="water-source-checkbox"
                          multiple
                          value={
                            formData.waterSource
                              ? formData.waterSource.split(",")
                              : []
                          }
                          onChange={handleChange}
                          input={<OutlinedInput label="Water Source" />}
                          renderValue={(selected) => selected.join(", ")}
                        >
                          {waterSourceOptions.map((option) => (
                            <MenuItem
                              key={option}
                              value={option}
                              onClick={(e) => {
                                if (e.target.tagName !== "INPUT") {
                                  handleCheckboxChange("waterSource", option);
                                }
                              }}
                            >
                              <Checkbox
                                checked={formData.waterSource.includes(option)}
                                onChange={() =>
                                  handleCheckboxChange("waterSource", option)
                                }
                              />
                              <ListItemText primary={option} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="eBillGenerateDate" className="form-label">
                        Electricity bill generation date (1-31)
                      </label>
                      <input
                        type="number"
                        name="eBillGenerateDate"
                        className="form-control"
                        value={formData.eBillGenerateDate}
                        onChange={handleChange}
                        placeholder="Enter Electricity bill generation date"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="eBillDueDate" className="form-label">
                        Electricity bill due date (1-31)
                      </label>
                      <input
                        type="number"
                        name="eBillDueDate"
                        className="form-control"
                        value={formData.eBillDueDate}
                        onChange={handleChange}
                        placeholder="Enter Electricity bill due date"
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyLat" className="form-label">
                        Property Latitude
                      </label>
                      <input
                        type="number"
                        name="propertyLat"
                        className="form-control"
                        value={formData.propertyLat}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyLong" className="form-label">
                        Property Longitude
                      </label>
                      <input
                        type="number"
                        name="propertyLong"
                        className="form-control"
                        value={formData.propertyLong}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="handOverDate" className="form-label">
                        Hand Over Date
                      </label>
                      <Flatpickr
                        className={`form-control ${
                          errors.handOverDate ? "is-invalid" : ""
                        }`}
                        value={
                          formData.handOverDate
                            ? new Date(formData.handOverDate)
                            : null
                        }
                        placeholder="Enter Hand Over Date"
                        onChange={(date) =>
                          setFormData({
                            ...formData,
                            handOverDate: date.length
                              ? date[0].toISOString()
                              : "",
                          })
                        }
                        options={{
                          dateFormat: "d-M-Y",
                          monthSelectorType: "static",
                        }}
                      />
                      {errors.handOverDate && (
                        <div className="invalid-feedback">
                          {errors.handOverDate}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="rentStatDate" className="form-label">
                        Rent Start Date
                      </label>
                      <Flatpickr
                        className={`form-control ${
                          errors.rentStatDate ? "is-invalid" : ""
                        }`}
                        value={
                          formData.rentStatDate
                            ? new Date(formData.rentStatDate)
                            : null
                        }
                        placeholder="Enter Hand Over Date"
                        onChange={(date) =>
                          setFormData({
                            ...formData,
                            rentStatDate: date.length
                              ? date[0].toISOString()
                              : "",
                          })
                        }
                        options={{
                          dateFormat: "d-M-Y",
                          monthSelectorType: "static",
                        }}
                      />
                      {errors.rentStatDate && (
                        <div className="invalid-feedback">
                          {errors.rentStatDate}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="renewalDate" className="form-label">
                        Renewal Date
                      </label>
                      <Flatpickr
                        className={`form-control ${
                          errors.renewalDate ? "is-invalid" : ""
                        }`}
                        value={
                          formData.renewalDate
                            ? new Date(formData.renewalDate)
                            : null
                        }
                        placeholder="Enter Hand Over Date"
                        onChange={(date) =>
                          setFormData({
                            ...formData,
                            renewalDate: date.length
                              ? date[0].toISOString()
                              : "",
                          })
                        }
                        options={{
                          dateFormat: "d-M-Y",
                          monthSelectorType: "static",
                        }}
                      />
                      {errors.renewalDate && (
                        <div className="invalid-feedback">
                          {errors.renewalDate}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="mapUrl" className="form-label">
                        Map URL (Note : Please provide embed Link)
                      </label>
                      <input
                        type="url"
                        name="mapUrl"
                        className="form-control"
                        value={formData.mapUrl}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3 d-flex align-items-center pt-4">
                      <label htmlFor="liftAvailble" className="form-label pe-3">
                        Lift Available
                      </label>
                      <div className="form-check form-switch form-switch-custom form-switch-primary">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="liftAvailble"
                          name="liftAvailble"
                          checked={formData.liftAvailble === "yes"}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              liftAvailble: e.target.checked ? "yes" : "no",
                            }))
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="liftAvailble"
                        >
                          {formData.liftAvailble === "yes" ? "Yes" : "No"}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyAddress" className="form-label">
                        Property Address{" "}
                        <span className="required-field">*</span>
                      </label>
                      <textarea
                        type="text"
                        name="propertyAddress"
                        className={`form-control ${
                          errors.propertyAddress ? "is-invalid" : ""
                        }`}
                        value={formData.propertyAddress}
                        onChange={handleChange}
                      />
                      {errors.propertyAddress && (
                        <div className="text-danger">
                          {errors.propertyAddress}
                        </div>
                      )}
                      <span
                        onClick={handleMapModalOpen}
                        style={{ color: "blue", cursor: "pointer" }}
                      >
                        Select Location on Map
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyNote" className="form-label">
                        Property Note <span className="required-field">*</span>
                      </label>
                      <textarea
                        type="text"
                        name="propertyNote"
                        className={`form-control ${
                          errors.propertyNote ? "is-invalid" : ""
                        }`}
                        value={formData.propertyNote}
                        onChange={handleChange}
                      />
                      {errors.propertyNote && (
                        <div className="text-danger">{errors.propertyNote}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="opsNote" className="form-label">
                        Ops Note <span className="required-field">*</span>
                      </label>
                      <textarea
                        type="text"
                        name="opsNote"
                        className={`form-control ${
                          errors.opsNote ? "is-invalid" : ""
                        }`}
                        value={formData.opsNote}
                        onChange={handleChange}
                        placeholder="Enter Ops Note"
                      />

                      {errors.opsNote && (
                        <div className="invalid-feedback">{errors.opsNote}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="pt-4">
                      <button
                        type="submit"
                        className="btn btn-secondary pt-1 pb-1"
                        disabled={isButtonDisabled}
                      >
                        {isButtonDisabled ? "Updating" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showMapModal} onHide={handleMapModalClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Select Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MapSection
            onAddressSelect={handleAddressSelection}
            onLatChange={handleLatChange}
            onLngChange={handleLngChange}
            onMapUrlChange={handleMapUrlChange}
            initialAddress={formData.propertyAddress}
            initialLat={formData.propertyLat}
            initiallong={formData.propertyLong}
            initialMapUrl={formData.mapUrl}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
