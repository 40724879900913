import React, { useState } from "react";
import TableHeader from "../Common/TableComponent/TableHeader";
import { TableDataStatusError } from "../Common/OtherElements/TableDataStatusError";
import { Link } from "react-router-dom";
import { PaymentUpdateModal } from "./PaymentUpdateModal";
import { verifyPayment } from "../../services/orderManagementService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal } from "react-bootstrap";
import Swal from "sweetalert2";
import { confirmVerified } from "../Common/OtherElements/confirmDeleteClone";

export const MaintainenceDetails = ({ customerData, handleFetchData }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState("");
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);

  const handleOpenModal = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedPaymentId(null);
  };

  const utilityDetails = customerData?.oMaintenance || [];
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const handlePaymentSuccess = () => {
    handleFetchData();
  };

  const handleVerifyPayment = async (paymentId, vStatus) => {
    try {
      let paymentType = "";

      if (customerData?.oRent?.some((item) => item.id === paymentId)) {
        paymentType = "Rent";
      } else if (
        customerData?.oUtility?.some((item) => item.id === paymentId)
      ) {
        paymentType = "Utility";
      } else if (
        customerData?.oMaintenance?.some((item) => item.id === paymentId)
      ) {
        paymentType = "Maintenance";
      } else if (
        customerData?.oOtherItems?.some((item) => item.id === paymentId)
      ) {
        paymentType = "Other";
      }

      if (!paymentType) {
        toast.error("Invalid payment type.");
        return;
      }

      await verifyPayment({ paymentId, paymentType, vStatus });
      toast.success(`Payment marked as ${vStatus}`);
      handleFetchData();
    } catch (error) {
      toast.error("Failed to verify payment.");
    }
  };
  const handleOpenConfirmationModal = async (paymentId, vStatus) => {
    const confirmed = await confirmVerified(`${vStatus}`);
    if (confirmed) {
      await handleVerifyPayment(paymentId, vStatus);
      Swal.fire(
        `${vStatus}!`,
        `The payment has been marked as ${vStatus}.`,
        "success"
      );
    }
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
    setSelectedPaymentId(null);
  };

  const handleConfirmVerification = async () => {
    await handleVerifyPayment(selectedPaymentId, "Verified");
    setConfirmationModalVisible(false);
  };

  return (
    <div className="card mt-xxl-n5">
      <div className="card-header">
        <h5 className="mb-sm-1 mt-sm-1">Maintainence Details</h5>
      </div>
      <div className="table-responsive">
        <table className="table table-nowrap align-middle table-bordered">
          <TableHeader
            columns={[
              "#",
              "From Date",
              "To Date",
              "Amount",
              "Tax Amount",
              "Total Amount",
              "Payment Id",
              "Payment Status",
              "Paid On",
              "Updated On",
              "Add Payment",
            ]}
          />
          <tbody className="manage-page-group-table-values">
            {utilityDetails.length === 0 ? (
              <TableDataStatusError colspan="12" />
            ) : (
              utilityDetails.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{formatDate(item.fromDate)}</td>
                  <td>{formatDate(item.toDate)}</td>

                  <td>Rs. {item.amount}</td>
                  <td>Rs. {item.taxAmount}</td>
                  <td>Rs. {item.totalAmount}</td>
                  <td>{item.paymentId}</td>
                  <td>
                    {item.paymentStatus === "Initiated" && (
                      <span className="badge rounded-pill badge-soft-primary me-2">
                        Initiated
                      </span>
                    )}
                    {item.paymentStatus === "Success" && (
                      <span className="badge rounded-pill badge-soft-success me-2">
                        Success
                      </span>
                    )}
                    {item.paymentStatus === "Failed" && (
                      <span className="badge rounded-pill badge-soft-danger me-2">
                        Failed
                      </span>
                    )}
                    {item.paymentStatus === "Pending" && (
                      <span className="badge rounded-pill badge-soft-warning me-2">
                        Pending
                      </span>
                    )}
                    {item.vStatus === "Not Verified" && (
                      <span className="badge badge-outline-warning">
                        Not Verified
                      </span>
                    )}
                    {item.vStatus === "Verified" && (
                      <span className="badge badge-outline-secondary">
                        Verified
                      </span>
                    )}
                  </td>
                  <td>{formatDate(item.paidOn)}</td>
                  <td>{formatDate(item.updatedOn)}</td>
                  <td>
                    {item.paymentStatus === "Success" ? (
                      <Button
                        variant={
                          item.vStatus === "Verified" ? "danger" : "primary"
                        }
                        className="badge"
                        style={{ textDecoration: "none", color: "#fff" }}
                        onClick={() =>
                          handleOpenConfirmationModal(
                            item.id,
                            item.vStatus === "Verified"
                              ? "Not Verified"
                              : "Verified"
                          )
                        }
                      >
                        {item.vStatus === "Verified"
                          ? "Unverify Payment"
                          : "Verify Payment"}
                      </Button>
                    ) : (
                      <span
                        className="badge text-bg-danger"
                        style={{ fontSize: "11px" }}
                      >
                        <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#fff" }}
                          onClick={() =>
                            handleOpenModal(item.orderGuid, item.rentAmount)
                          }
                        >
                          Add Payment
                        </Link>
                      </span>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <PaymentUpdateModal
        show={modalVisible}
        onClose={handleCloseModal}
        orderId={selectedPaymentId}
        pageIdentify="maintenance"
        onPaymentSuccess={handlePaymentSuccess}
      />
      <Modal
        show={confirmationModalVisible}
        onHide={handleCloseConfirmationModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Payment Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to verify the payment?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmationModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmVerification}>
            Yes, Verify
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
