import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  fetchExpnature,
  deleteExpnature,
} from "../../../services/expenseNatureService";
import { paginateData, calculateTotalPages } from "../../../assets/js/script";
import TableHeader from "../../Common/TableComponent/TableHeader";
import EntriesDropdown from "../../Common/TableComponent/EntriesDropdown";
import TablesRow from "../../Common/TableComponent/TablesRow";
import { Pagination } from "../../Common/TableComponent/Pagination";
import { Loading } from "../../Common/OtherElements/Loading";
import Swal from "sweetalert2";
import { confirmDelete } from "../../Common/OtherElements/confirmDeleteClone";
import { TableDataStatusError } from "../../Common/OtherElements/TableDataStatusError";
import { handleErrors } from "../../../utils/errorHandler";
import { usePageLevelAccess } from "../../../hooks/usePageLevelAccess";
import { useNavigate } from "react-router-dom";
import { AddExpNature } from "./AddExpNature";

export const ManageExpNature = () => {
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [expenseNatureTitles, setExpenseNatureTitles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPageGroup, setSelectedPageGroup] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageAccessDetails, setPageAccessDetails] = useState([]);
  const PageLevelAccessurl = "bhk-type";
  const navigate = useNavigate();
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.addAccess && !pageAccessData.viewAccess) {
        navigate("/404-error-page");
      } else {
        setPageAccessDetails(pageAccessData);
      }
    } else {
      console.log("No page access details found");
    }
  }, [pageAccessData, navigate]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchExpnature();
      setExpenseNatureTitles(response.result);
    } catch (err) {
      handleErrors(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredData = useMemo(() => {
    return expenseNatureTitles.filter((item) =>
      item.expenseNatureTitle.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [expenseNatureTitles, searchQuery]);

  const currentData = useMemo(() => {
    return paginateData(filteredData, currentPage, entriesPerPage);
  }, [filteredData, currentPage, entriesPerPage]);

  const totalPages = useMemo(() => {
    return calculateTotalPages(filteredData.length, entriesPerPage);
  }, [filteredData.length, entriesPerPage]);

  const handleEntriesChange = useCallback((value) => {
    setEntriesPerPage(value);
    setCurrentPage(1);
  }, []);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
  }, []);

  const handleDelete = useCallback(async (groupId) => {
    const confirmed = await confirmDelete("Expense Nature");

    if (confirmed) {
      try {
        await deleteExpnature(groupId);
        setExpenseNatureTitles((prev) =>
          prev.filter((item) => item.id !== groupId)
        );
        Swal.fire(
          "Deleted!",
          "The Expense Nature has been deleted successfully.",
          "success"
        );
      } catch (err) {
        handleErrors(err);
      }
    }
  }, []);

  return (
    <>
      {pageAccessDetails.addAccess ? (
        <AddExpNature
          editMode={editMode}
          initialData={selectedPageGroup}
          onSuccess={fetchData}
          setSelectedPageGroup={setSelectedPageGroup}
          setEditMode={setEditMode}
        />
      ) : (
        ""
      )}
      {pageAccessDetails.viewAccess ? (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card mt-xxl-n5">
              <div className="card-header">
                <h5 className="mb-sm-2 mt-sm-2">Manage Expense Nature</h5>
              </div>
              <div className="card-body manage-amenity-master-card-body">
                <div className="pagination-details-responsive justify-content-between mb-3">
                  <EntriesDropdown
                    entriesPerPage={entriesPerPage}
                    onEntriesChange={handleEntriesChange}
                  />
                  <div>
                    <input
                      type="text"
                      placeholder="Search..."
                      className="form-control mb-2"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="table-responsive">
                    <table className="table align-middle table-bordered">
                      <TableHeader
                        columns={["#", "Expense Nature", "Added On", "Action"]}
                      />
                      <tbody className="manage-page-group-table-values">
                        {currentData.length === 0 ? (
                          <TableDataStatusError colspan="4" />
                        ) : (
                          currentData.map((item, index) => (
                            <TablesRow
                              key={item.id}
                              rowData={{
                                id:
                                  (currentPage - 1) * entriesPerPage +
                                  index +
                                  1,
                                expenseNatureTitle: item.expenseNatureTitle,
                                addedon: new Date(
                                  item.addedOn
                                ).toLocaleDateString(),
                              }}
                              columns={["id", "expenseNatureTitle", "addedon"]}
                              hideIcons={false}
                              onEdit={() => {
                                setSelectedPageGroup(item);
                                setEditMode(true);
                                window.scrollTo({ top: 0, behavior: "smooth" });
                              }}
                              onDelete={() => handleDelete(item.id)}
                              pageLevelAccessData={pageAccessDetails}
                            />
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                )}

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  totalEntries={filteredData.length}
                  entriesPerPage={entriesPerPage}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
