import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import { addAssetsReports } from "../../services/AssestsReport";
import { handleErrors } from "../../utils/errorHandler";

const options = [
  { value: "Product", label: "Product" },
  { value: "Brand", label: "Brand" },
  { value: "Specification", label: "Specification" },
];

export const ManageAssestReports = () => {
  const [manageAssessts, setManageAssessts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fType, setFType] = useState(options[0]);

  const fetchTableData = async (filterType) => {
    setLoading(true);
    const formData = { fType: filterType.value };

    try {
      const response = await addAssetsReports(formData);
      console.log("Full API Response:", response);

      if (response) {
        console.log("Setting Data:", response.data.result);
        setManageAssessts(response.data.result);
      } else {
        console.warn("No valid data found!");
        setManageAssessts([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setManageAssessts([]);
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData(fType);
  }, [fType]);

  const handleTypeChange = (selectedOption) => {
    setFType(selectedOption);
  };

  const loadOptions = (inputValue, callback) => {
    callback(
      options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      )
    );
  };

  const renderTableHeaders = () => {
    if (fType.value === "Specification") {
      return (
        <tr>
          <th>Product Name</th>
          <th>Brand Name</th>
          <th>Specification</th>
          <th>Total Qty</th>
          <th>Available Qty</th>
        </tr>
      );
    } else if (fType.value === "Brand") {
      return (
        <tr>
          <th>Product Name</th>
          <th>Brand Name</th>
          <th>Total Qty</th>
          <th>Available Qty</th>
        </tr>
      );
    } else if (fType.value === "Product") {
      return (
        <tr>
          <th>Product Name</th>
          <th>Total Qty</th>
          <th>Available Qty</th>
        </tr>
      );
    }
  };

  return (
    <div className="row">
      <div className="col-xxl-12">
        <div className="card mt-xxl-n5">
          <div className="card-header">
            <h5 className="mb-sm-2 mt-sm-2">Manage Assests Reports</h5>
          </div>
          <div className="card-body">
            <div className="d-flex mb-3">
              <div className="search-input col-lg-3 col-md-6 col-sm-12">
                <label htmlFor="Filter Type" className="form-label me-2">
                  Filter Type:
                </label>
                <AsyncSelect
                  cacheOptions
                  defaultValue={fType}
                  defaultOptions={options}
                  loadOptions={loadOptions}
                  onChange={handleTypeChange}
                  placeholder="Select Type"
                />
              </div>
            </div>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <table className="table table-bordered">
                <thead>{renderTableHeaders()}</thead>
                <tbody>
                  {manageAssessts?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.productName || "N/A"}</td>
                      {fType.value !== "Product" && (
                        <td>{item?.brandName || "-"}</td>
                      )}
                      {fType.value === "Specification" && (
                        <td>{item?.specification || "-"}</td>
                      )}
                      <td>{item?.totalQty || 0}</td>
                      <td>{item?.avlQuantity || 0}</td>
                    </tr>
                  )) || []}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
