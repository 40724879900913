import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  fetchPropertiesBySupervisor,
  fetchSupervisorsList,
  reAssignSupervisor,
} from "../../services/AssignProperties";
import { fetchUserProfile } from "../../services/newUserService.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from "../../utils/errorHandler";

export const SupportInfoDetails = ({ customerData }) => {
  const [errors, setErrors] = useState({
    propertyGuid: [],
    userGuid: "",
  });
  const [formData, setFormData] = useState({
    propertyGuid: [],
    userGuid: "",
  });
  const [supervisors, setSupervisors] = useState([]);
  const [showReAssignModal, setShowReAssignModal] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [userRoleId, setUserRoleId] = useState("");

  const handleImageClick = () => {
    setShowImageModal(true);
  };

  const handleCloseReAssignModal = () => {
    setShowReAssignModal(false);
  };

  const handleCloseImageModal = () => {
    setShowImageModal(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No Date Available";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(date);
  };

  const customerDetailsFields = [
    { label: "Ticket ID", value: customerData.ticketId || "-" },
    {
      label: "Tenants",
      value: (
        <>
          {customerData.userName || "-"}
          <br />
          {customerData.phoneNo || "-"}
        </>
      ),
    },
    {
      label: "Caretaker",
      value: (
        <>
          {customerData.caretakerName || "-"}
          <br />
          {customerData.caretakerContact || "-"}
        </>
      ),
    },
    {
      label: "Supervisor",
      value: (
        <>
          {customerData.reAssignSupervisorName ||
            customerData.supervisorName ||
            "-"}
          <br />
          {customerData.reAssignSupervisorContact ||
            customerData.supervisorContact ||
            "-"}
        </>
      ),
    },

    { label: "Property Name", value: customerData.propertyName || "-" },

    { label: "Flat Number", value: customerData.flatNo || "-" },
    { label: "Type of Support", value: customerData.typeOfSupport || "-" },
    { label: "Support Status", value: customerData.supportStatus || "-" },
    { label: "Created By", value: customerData.createdBy || "-" },
    { label: "Updated By", value: customerData.updatedBy || "-" },
    { label: "Created On", value: formatDate(customerData.createdOn) || "-" },
    { label: "Updated On", value: formatDate(customerData.updatedOn) || "-" },
  ];

  const renderOrderStatusBadge = (status) => {
    let badgeClass = "";
    if (status === "Created") {
      badgeClass = "badge-soft-secondary";
    } else if (status === "InProgress") {
      badgeClass = "badge-soft-warning";
    } else if (status === "Waiting") {
      badgeClass = "badge-soft-warning";
    } else if (status === "Closed") {
      badgeClass = "badge-soft-success";
    } else if (status === "Opened") {
      badgeClass = "badge-soft-danger";
    } else if (status === "Resolved") {
      badgeClass = "badge-soft-success";
    }
    return <span className={`badge rounded-pill ${badgeClass}`}>{status}</span>;
  };
  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const supervisorData = await fetchSupervisorsList();
        setSupervisors(supervisorData);
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchSupervisors();
  }, []);

  const handleSubmit = async () => {
    if (!formData.userGuid) {
      setErrors({ ...errors, userGuid: "Supervisor is required" });
      return;
    }

    try {
      const supportId = customerData.id;
      const supervisorGuid = formData.userGuid;

      const result = await reAssignSupervisor(supportId, supervisorGuid);

      if (result) {
        toast.success("Supervisor reassigned successfully!");
        handleCloseReAssignModal();
      } else {
        toast.error("Failed to re-assign supervisor.");
      }
    } catch (error) {
      console.error("Error re-assigning supervisor:", error);
      toast.error("An error occurred.");
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userData = await fetchUserProfile();
        setUserRoleId(userData.data.result.userRole);
        console.log("userid", userData.data.result.userRole);
      } catch (error) {
        handleErrors(error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <div className="card mt-xxl-n5">
      <div className="card-header d-flex justify-content-between">
        <h5 className="mb-sm-1 mt-sm-1">Support Details</h5>
        {userRoleId !== 16 && userRoleId !== 17 && (
          <button
            className="btn btn-primary"
            onClick={() => setShowReAssignModal(true)}
          >
            Re-assign Supervisor
          </button>
        )}
      </div>
      <div className="card-body p-4">
        <div className="row justify-content-center">
          <div className="col-xxl-12">
            <div className="row">
              <div className="col-lg-12">
                <div className="card-body p-4">
                  <div className="row g-3">
                    {customerDetailsFields.map((field, index) => (
                      <div className="col-lg-3 col-6 mb-3" key={index}>
                        <p className="mb-2 fw-semibold">{field.label}</p>
                        <h5 className="fs-14 mb-0">
                          <span id="invoice-no" className="sub-title-detail">
                            {field.label === "Support Status"
                              ? renderOrderStatusBadge(field.value)
                              : field.value}
                          </span>
                        </h5>
                      </div>
                    ))}
                    <div className="col-lg-6 col-6 mb-3">
                      <p className="mb-2 fw-semibold">Description</p>
                      <h5 className="fs-14 mb-0">
                        <span id="invoice-no" className="sub-title-detail">
                          {customerData.description || "-"}
                        </span>
                      </h5>
                    </div>
                    <div className="col-lg-6 col-6 mb-3">
                      <p className="mb-2 fw-semibold">Attachment</p>
                      {customerData.attachment ? (
                        <img
                          src={customerData.attachment}
                          alt="Support management attachment"
                          width="100px"
                          height="100px"
                          style={{ cursor: "pointer" }}
                          onClick={handleImageClick}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showReAssignModal}
        onHide={handleCloseReAssignModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Supervisor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-8 col-md-6 col-sm-12">
            <div className="mb-3">
              <label htmlFor="supervisorId" className="form-label">
                Supervisor <span className="required-field">*</span>
              </label>
              <select
                name="userGuid"
                className={`form-select ${errors.userGuid ? "is-invalid" : ""}`}
                value={formData.userGuid}
                onChange={async (e) => {
                  const selectedSupervisor = e.target.value;
                  setFormData((prevData) => ({
                    ...prevData,
                    userGuid: selectedSupervisor,
                  }));

                  if (selectedSupervisor) {
                    try {
                      const assignedProperties =
                        await fetchPropertiesBySupervisor(selectedSupervisor);
                      setFormData((prevData) => ({
                        ...prevData,
                        propertyGuid: assignedProperties.map(
                          (property) => property.propertyGuid
                        ),
                      }));
                    } catch (error) {
                      console.error(
                        "Error fetching properties for supervisor:",
                        error
                      );
                    }
                  } else {
                    setFormData((prevData) => ({
                      ...prevData,
                      propertyGuid: [],
                    }));
                  }
                }}
              >
                <option value="">Select Supervisor</option>
                {supervisors.map((supervisor) => (
                  <option key={supervisor.userGuid} value={supervisor.userGuid}>
                    {supervisor.userName}
                  </option>
                ))}
              </select>
              {errors.userGuid && (
                <div className="invalid-feedback">{errors.userGuid}</div>
              )}
            </div>
          </div>
          <button className="btn btn-success" onClick={handleSubmit}>
            Submit
          </button>
        </Modal.Body>
      </Modal>
      <Modal show={showImageModal} onHide={handleCloseImageModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Attached Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img
            src={customerData.attachment}
            alt="Support management attachment"
            className="img-fluid"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
