export const FormSection3 = ({
  label,
  labelStar,
  name,
  onChange,
  error,
  value,
}) => (
  <div className="col-lg-12">
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {labelStar}
      </label>
      <textarea
        name={name}
        value={value}
        className={`form-control ${error ? "is-invalid" : ""}`}
        onChange={onChange}
        placeholder={`Enter ${label}`}
        rows="2"
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  </div>
);
