import React, { useState, useEffect, useCallback } from "react";
import {
  fetchSWKProperty,
  fetchExpManager,
} from "../../services/SWKPropertService";
import { fetchExpnature } from "../../services/expenseNatureService";
import { fetchFlats } from "../../services/FlatMasterService";
import {
  addPropertyExpense,
  fetchPropertyExpenseById,
  updatePropertyExpense,
} from "../../services/propertyExpenseService";
import { validatePropertyExpenseForm } from "../../utils/validation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "react-flatpickr";
import { useParams } from "react-router-dom";
import { handleErrors } from "../../utils/errorHandler";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { usePageLevelAccess } from "../../hooks/usePageLevelAccess";
import { useNavigate } from "react-router-dom";

export const AddPropertyExpenses = () => {
  const { id } = useParams();
  const [properties, setProperties] = useState([]);
  const [managerNames, setManagerNames] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("");
  //   const [selectedMname, setSelectedMname] = useState("");
  const [expenseNature, setExpenseNature] = useState([]);
  const [flats, setFlats] = useState([]);
  const [selectedFlat, setSelectedFlat] = useState("");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [description, setDescription] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [approvedBy, setApprovedBy] = useState("");
  const [expenseRecoverable, setExpenseRecoverable] = useState("");
  const [amount, setAmount] = useState("");
  const [expenseDate, setExpenseDate] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [apiError, setApiError] = useState({});
  const [previewUrl, setPreviewUrl] = useState(null);
  const [PageLevelAccessurl, setPageLevelAccessurl] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setPageLevelAccessurl("/property-expense/update/:id");
    } else {
      setPageLevelAccessurl("property-expense/add");
    }
  }, [id]);

  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);

  useEffect(() => {
    if (pageAccessData) {
      if (id) {
        if (!pageAccessData.editAccess) {
          navigate("/404-error-page");
        } else {
          return;
        }
      } else {
        if (!pageAccessData.addAccess) {
          navigate("/404-error-page");
        } else {
          return;
        }
      }
    } else {
      console.log("No page access details found");
    }
  });

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const data = await fetchPropertyExpenseById(id);
        setDescription(data.comments);
        setSelectedFlat(data.flatGuid);
        setSelectedProperty(data.propertyGuid);
        setApprovedBy(data.approvedBy);
        setSelectedProduct(data.natureOfExpense);
        setExpenseRecoverable(data.expenseRecoverable);
        setAmount(data.amount);
        setExpenseDate(data.expenseDate);
        setAttachment(data.attachment);
        setPreviewUrl(data.attachment);
      } catch (error) {
        handleErrors(error);
      }
    };

    if (id) {
      fetchCity();
    } else {
      setDescription("");
      setSelectedFlat("");
      setSelectedProperty("");
      setSelectedProduct("");
      setApprovedBy("");
      setExpenseRecoverable("");
      setAmount("");
      setExpenseDate("");
      setAttachment("");
      setPreviewUrl("");
    }
  }, [id]);

  useEffect(() => {
    const getProperties = async () => {
      try {
        const result = await fetchSWKProperty();
        setProperties(result);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };
    getProperties();
  }, []);

  useEffect(() => {
    const getMName = async () => {
      try {
        const result = await fetchExpManager();
        setManagerNames(result);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };
    getMName();
  }, []);

  useEffect(() => {
    const getFlats = async () => {
      if (selectedProperty) {
        try {
          const result = await fetchFlats(selectedProperty);
          setFlats(result);
        } catch (error) {
          console.error("Error fetching flats:", error);
        }
      } else {
        setFlats([]);
      }
    };
    getFlats();
  }, [selectedProperty]);

  const handleAttachmentChange = (e) => {
    const file = e.target.files[0];
    setAttachment(file);

    if (file) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
    } else {
      setPreviewUrl(null);
    }
  };

  const loadPropertyOptions = (inputValue, callback) => {
    const filteredProperties = properties.filter((property) =>
      property.propertyName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredProperties.map((property) => ({
          label: property.propertyName,
          value: property.propertyGuid,
        }))
      );
    }, 1000);
  };
  const loadMangerName = (inputValue, callback) => {
    const filteredManagerNames = managerNames.filter((mName) =>
      mName.managerName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredManagerNames.map((mName) => ({
          label: mName.managerName,
          value: mName.managerName,
        }))
      );
    }, 100);
  };

  const loadFlatOptions = (inputValue, callback) => {
    const filteredFlats = flats.filter((flat) =>
      flat.flatName.toLowerCase().includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredFlats.map((flat) => ({
          label: flat.flatName,
          value: flat.flatGuid,
        }))
      );
    }, 1000);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setApiError({});

      const validationErrors = validatePropertyExpenseForm({
        NatureOfExpense: selectedProduct,
        PropertyGuid: selectedProperty,
        FlatGuid: selectedFlat,
        ApprovedBy: approvedBy,
        ExpenseRecoverable: expenseRecoverable,
        Amount: amount,
        ExpenseDate: expenseDate,
        Comments: description,
        Attachment: attachment,
      });

      if (Object.keys(validationErrors).length > 0) {
        setApiError(validationErrors);
        return;
      }

      try {
        setIsButtonDisabled(true);

        const formData = new FormData();
        formData.append("NatureOfExpense", selectedProduct);
        formData.append("PropertyGuid", selectedProperty);
        formData.append("FlatGuid", selectedFlat);
        formData.append("Comments", description);
        formData.append("ApprovedBy", approvedBy);
        formData.append("ExpenseRecoverable", expenseRecoverable);
        formData.append("Amount", amount);
        formData.append("ExpenseDate", expenseDate);
        if (attachment) {
          formData.append("Attachment", attachment);
        }

        if (id) {
          formData.append("id", id);
          await updatePropertyExpense(formData);
          toast.success("Expense updated successfully!");
        } else {
          await addPropertyExpense(formData);
          toast.success("Expense added successfully!");
          setSelectedFlat("");
          setSelectedProduct("");
          setDescription("");
          setApprovedBy("");
          setExpenseRecoverable("");
          setAmount("");
          setExpenseDate("");
          setAttachment(null);
          setPreviewUrl(null);
        }
      } catch (error) {
        setApiError({
          formError: id
            ? "Error updating property expense"
            : "Error adding property expense",
        });
        toast.error(
          id
            ? "Error occurred while updating expense!"
            : "Error occurred while adding expense!"
        );
      } finally {
        setIsButtonDisabled(false);
      }
    },
    [
      id,
      selectedFlat,
      selectedProduct,
      description,
      approvedBy,
      expenseRecoverable,
      amount,
      expenseDate,
      attachment,
      selectedProperty,
    ]
  );

  useEffect(() => {
    const getProperties = async () => {
      try {
        const response = await fetchExpnature();
        setExpenseNature(Array.isArray(response.result) ? response.result : []);
      } catch (error) {
        console.error("Error fetching properties:", error);
        setExpenseNature([]);
      }
    };
    getProperties();
  }, []);
  const loadExpenseOptions = (inputValue, callback) => {
    const filteredProperties = expenseNature.filter((property) =>
      property.expenseNatureTitle
        .toLowerCase()
        .includes(inputValue.toLowerCase())
    );

    setTimeout(() => {
      callback(
        filteredProperties.map((property) => ({
          label: property.expenseNatureTitle,
          value: property.expenseNatureTitle,
        }))
      );
    }, 1000);
  };
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">Property Expense</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="ri-home-2-fill"></i>
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  {id
                    ? `Update Property Expense-${id}`
                    : "Add Property Expense"}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">
                {id ? "Update Property Expense" : "Add Property Expense"}
              </h5>
            </div>
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="search-input">
                      <label htmlFor="search" className="form-label me-2">
                        Nature of Expense:
                      </label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadExpenseOptions}
                        defaultOptions={(expenseNature || []).map(
                          (property) => ({
                            label: property.expenseNatureTitle,
                            value: property.expenseNatureTitle,
                          })
                        )}
                        onChange={(selectedOption) =>
                          setSelectedProduct(selectedOption?.label || "")
                        }
                        value={
                          selectedProduct
                            ? {
                                label: selectedProduct,
                                value: expenseNature.find(
                                  (property) =>
                                    property.expenseNatureTitle ===
                                    selectedProduct
                                )?.expenseNatureTitle,
                              }
                            : null
                        }
                        isClearable
                        placeholder="Select Expense Nature"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="propertyName" className="form-label">
                        Property Name <span className="required-field">*</span>
                      </label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadPropertyOptions}
                        defaultOptions={properties.map((property) => ({
                          label: property.propertyName,
                          value: property.propertyGuid,
                        }))}
                        onChange={(selectedOption) =>
                          setSelectedProperty(selectedOption?.value || "")
                        }
                        value={
                          selectedProperty
                            ? {
                                label: properties.find(
                                  (property) =>
                                    property.propertyGuid === selectedProperty
                                )?.propertyName,
                                value: selectedProperty,
                              }
                            : null
                        }
                        isClearable
                        placeholder="Select Property"
                        className={apiError.PropertyGuid ? "is-invalid" : ""}
                      />
                      {apiError.PropertyGuid && (
                        <div className="invalid-feedback">
                          {apiError.PropertyGuid}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="flatGuid" className="form-label">
                        Flat Name
                      </label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadFlatOptions}
                        defaultOptions={flats.map((flat) => ({
                          label: flat.flatName,
                          value: flat.flatGuid,
                        }))}
                        onChange={(selectedOption) =>
                          setSelectedFlat(selectedOption?.value || "")
                        }
                        value={
                          selectedFlat
                            ? {
                                label: flats.find(
                                  (flat) => flat.flatGuid === selectedFlat
                                )?.flatName,
                                value: selectedFlat,
                              }
                            : null
                        }
                        isClearable
                        placeholder="Select Flat"
                        className={apiError.flatGuid ? "is-invalid" : ""}
                      />
                      {apiError.flatGuid && (
                        <div className="invalid-feedback">
                          {apiError.flatGuid}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="ApprovedBy" className="form-label">
                        ApprovedBy <span className="required-field">*</span>
                      </label>
                      <AsyncSelect
                        cacheOptions
                        loadOptions={loadMangerName}
                        defaultOptions={managerNames.map((mName) => ({
                          label: mName.managerName,
                          value: mName.managerName,
                        }))}
                        onChange={(selectedOption) =>
                          setApprovedBy(selectedOption?.value || "")
                        }
                        // onChange={(e) => setSelectedMname(e.target.value)}
                        value={
                          approvedBy
                            ? {
                                label: managerNames.find(
                                  (mName) => mName.managerName === approvedBy
                                )?.managerName,
                                value: approvedBy,
                              }
                            : null
                        }
                        isClearable
                        placeholder="Select Manager"
                        className={apiError.ApprovedBy ? "is-invalid" : ""}
                      />
                      {apiError.ApprovedBy && (
                        <div className="invalid-feedback">
                          {apiError.ApprovedBy}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="Amount" className="form-label">
                        Amount <span className="required-field">*</span>
                      </label>
                      <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        className={`form-control ${
                          apiError.Amount ? "is-invalid" : ""
                        }`}
                      />
                      {apiError.Amount && (
                        <div className="invalid-feedback">
                          {apiError.Amount}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label
                        htmlFor="ExpenseRecoverable"
                        className="form-label"
                      >
                        Expense Recoverable{" "}
                        <span className="required-field">*</span>
                      </label>
                      <select
                        value={expenseRecoverable}
                        onChange={(e) => setExpenseRecoverable(e.target.value)}
                        className={`form-select ${
                          apiError.ExpenseRecoverable ? "is-invalid" : ""
                        }`}
                      >
                        <option value="">Select a ExpenseRecoverable</option>
                        <option value="Expense Recoverable (Owner)">
                          Expense Recoverable (Owner)
                        </option>
                        <option value="Expense Non Recoverable">
                          Expense Non Recoverable
                        </option>
                      </select>

                      {apiError.ExpenseRecoverable && (
                        <div className="invalid-feedback">
                          {apiError.ExpenseRecoverable}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="ExpenseDate" className="form-label">
                        Expense Date<span className="required-field">*</span>
                      </label>
                      <Flatpickr
                        className={`form-control ${
                          apiError.ExpenseDate ? "is-invalid" : ""
                        }`}
                        value={expenseDate ? new Date(expenseDate) : ""}
                        placeholder="Enter Expense Date"
                        onChange={(date) => {
                          const formattedDate = new Date(date[0]).toISOString();
                          setExpenseDate(formattedDate);
                        }}
                        options={{
                          dateFormat: "d-M-Y",
                          monthSelectorType: "static",
                        }}
                      />

                      {apiError.ExpenseDate && (
                        <p className="text-danger">{apiError.ExpenseDate}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="description" className="form-label">
                        Comments <span className="required-field">*</span>
                      </label>
                      <textarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        className={`form-control ${
                          apiError.description ? "is-invalid" : ""
                        }`}
                        rows="4"
                      />
                      {apiError.description && (
                        <div className="invalid-feedback">
                          {apiError.description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="attachment" className="form-label">
                      Attachment (Note: Please attach image or file within 2MB)
                    </label>
                    <input
                      type="file"
                      onChange={handleAttachmentChange}
                      className="form-control"
                    />
                  </div>

                  {previewUrl && attachment && (
                    <div className="mb-3">
                      <label className="form-label">Attachment Preview:</label>
                      <br />
                      {attachment.type &&
                      attachment.type.startsWith("image/") ? (
                        <img
                          src={previewUrl}
                          alt="Preview"
                          style={{ maxWidth: "30%", height: "auto" }}
                        />
                      ) : (
                        <img src={previewUrl} alt="update preview" />
                      )}
                    </div>
                  )}
                </div>
                <button
                  type="submit"
                  className="btn btn-secondary"
                  disabled={isButtonDisabled}
                >
                  {id ? "Update" : "Add"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
