import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const createProduct = async (productData) => {
  const response = await axiosInstance.post(
    "masters/product",
    {
      productName: productData.productName,
      productType: productData.productType,
    },
    { headers }
  );
  return response.data;
};

export const fetchProduct = async () => {
  const response = await axiosInstance.get("masters/product", { headers });
  return response.data;
};

export const deleteProduct = async (productId) => {
  const response = await axiosInstance.delete(`masters/product/${productId}`, {
    headers,
  });
  return response.data;
};

export const fetchProductById = async (productId) => {
  const response = await axiosInstance.get(`masters/product/${productId}`, {
    headers,
  });
  return response.data.result;
};

export const updateProduct = async (productData) => {
  const response = await axiosInstance.put("masters/product", productData, {
    headers,
  });
  return response.data;
};
