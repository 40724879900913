import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const addAssetsReports = async (formData) => {
  const response = await axiosInstance.post("reports/asset-report", formData, {
    headers,
  });
  return response;
};
