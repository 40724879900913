import React, { useState, useEffect, useCallback } from "react";
import {
  uploadDocument,
  fetchPropertyDocuments,
} from "../../../services/PropertyDocumentsServices";
import { validatePropertyDocument } from "../../../utils/validation";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleErrors } from "../../../utils/errorHandler";

export const AddPropertyDocumnets = ({
  editMode = false,
  initialData = {},
  onSuccess,
  setEditMode,
  propertyDetails,
}) => {
  const [formData, setFormData] = useState({
    propertyGuid: propertyDetails,
    docTitle: "",
    remark: "",
    attachment: null,
  });
  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (editMode && initialData.id) {
        try {
          const data = await fetchPropertyDocuments(initialData.id);
          setFormData({
            docTitle: data.docTitle || "",
            attachment: null,
            remark: null,
            propertyGuid: propertyDetails,
          });
        } catch (error) {
          handleErrors(error);
        }
      } else {
        setFormData({
          docTitle: "",
          attachment: null,
          remark: null,
          propertyGuid: propertyDetails,
        });
      }
    };

    fetchData();
  }, [editMode, initialData.id, propertyDetails]);
  console.log("propertyDetails", propertyDetails);
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "attachment" && files[0]) {
      setFormData((prevData) => ({ ...prevData, [name]: files[0] }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));

      const file = files[0];
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => setPreview(reader.result);
        reader.readAsDataURL(file);
      } else {
        setPreview(null);
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      const { valid, errors: validationErrors } =
        validatePropertyDocument(formData);
      setErrors(validationErrors);

      if (valid) {
        try {
          setIsButtonDisabled(true);
          await uploadDocument(formData);
          toast.success("Document added successfully!");
          setFormData({
            docTitle: "",
            attachment: null,
            propertyGuid: "",
            remark: "",
          });
          setPreview(null);
          if (onSuccess) {
            onSuccess();
          }
        } catch (error) {
          handleErrors(error);
        } finally {
          setIsButtonDisabled(false);
        }
      }
    },
    [formData, onSuccess]
  );

  return (
    <>
      <style>
        {`
       
        .ri-pencil-fill:before {
          display:none;
        }
    `}
      </style>
      <div className="row">
        <div className="col-xxl-12">
          <div className="card mt-xxl-n5">
            <div className="card-header">
              <h5 className="mb-sm-1 mt-sm-1">
                {editMode ? "Update Owner Document" : "Add Owner Document"}
              </h5>
            </div>
            <div className="card-body p-4">
              <form
                onSubmit={handleSubmit}
                method="POST"
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="docTitle" className="form-label">
                        Document Title <span className="required-field">*</span>
                      </label>
                      <input
                        type="text"
                        name="docTitle"
                        value={formData.docTitle}
                        onChange={handleInputChange}
                        className={`form-control ${
                          errors.docTitle ? "is-invalid" : ""
                        }`}
                      />
                      {errors.docTitle && (
                        <div className="invalid-feedback">
                          {errors.docTitle}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="attachment" className="form-label">
                        Attachment <span className="required-field">*</span>
                      </label>
                      <input
                        type="file"
                        name="attachment"
                        onChange={handleInputChange}
                        className={`form-control ${
                          errors.attachment ? "is-invalid" : ""
                        }`}
                      />
                      {errors.attachment && (
                        <div className="invalid-feedback">
                          {errors.attachment}
                        </div>
                      )}
                    </div>
                    {preview && (
                      <div className="mb-3">
                        <img
                          src={preview}
                          alt="Attachment Preview"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-3">
                      <label htmlFor="remark" className="form-label">
                        Remark
                      </label>
                      <textarea
                        name="remark"
                        value={formData.remark}
                        onChange={handleInputChange}
                        className="form-control"
                        rows="3"
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="pt-4">
                      <button
                        type="submit"
                        className="btn btn-secondary pt-1 pb-1 p-3"
                        disabled={isButtonDisabled}
                      >
                        {isButtonDisabled
                          ? editMode
                            ? "Updating"
                            : "Saving"
                          : editMode
                          ? "Update"
                          : "Save"}
                      </button>
                      {editMode && (
                        <button
                          type="button"
                          onClick={() => setEditMode(false)}
                          className="btn btn-danger ms-1 pt-1 pb-1 p-3"
                        >
                          Cancel
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
