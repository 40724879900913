import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import TableHeader from "../Common/TableComponent/TableHeader";
import TablesRow from "../Common/TableComponent/TablesRow";
import { Loading } from "../Common/OtherElements/Loading";
import {
  paginationSupports,
  deleteSupport,
} from "../../services/SupportService";
import Swal from "sweetalert2";
import { confirmDelete } from "../Common/OtherElements/confirmDeleteClone";
import { TableDataStatusError } from "../Common/OtherElements/TableDataStatusError";
import { handleErrors } from "../../utils/errorHandler";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import { Pagination } from "../Common/TableComponent/Pagination";
import { Link } from "react-router-dom";
import { usePageLevelAccess } from "../../hooks/usePageLevelAccess";
import * as XLSX from "xlsx";
import allImages from "../../assets/images-import";
import { useParams } from "react-router-dom";

export const ManageSupportManagement = () => {
  const { status } = useParams();
  const [entriesPerPage, setEntriesPerPage] = useState(30);
  const [selectedProduct, setSelectedProduct] = useState("");
  // const [apiError, setApiError] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedTerm, setSearchedTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [supportStatus, setsupportStatus] = useState(status);
  const [pageAccessDetails, setPageAccessDetails] = useState([]);
  const navigate = useNavigate();
  const PageLevelAccessurl = "support";
  const { pageAccessData } = usePageLevelAccess(PageLevelAccessurl);
  const [downloadBtnDisable, setDownloadBtnDisable] = useState(false);

  useEffect(() => {
    if (pageAccessData) {
      if (!pageAccessData.addAccess && !pageAccessData.viewAccess) {
        navigate("/404-error-page");
      } else {
        setPageAccessDetails(pageAccessData);
      }
    } else {
      console.log("No page access details found");
    }
  }, [pageAccessData, navigate]);

  const searchInputRef = useRef(null);

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      const formData = {
        pageSize: entriesPerPage,
        pageNo: currentPage,
        fromDate,
        toDate,
        sParam: searchedTerm,
        supportStatus: supportStatus,
        typeOfSupport: selectedProduct,
      };
      try {
        const response = await paginationSupports(formData);
        const { data } = response;
        setProperties(data.result);
        setTotalCount(data.result[0]?.totalCount || 0);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [
    entriesPerPage,
    currentPage,
    fromDate,
    toDate,
    searchedTerm,
    supportStatus,
    selectedProduct,
  ]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEntriesPerPageChange = (e) => {
    setEntriesPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handleDelete = async (propertyId) => {
    const confirmed = await confirmDelete("Support");
    if (confirmed) {
      try {
        await deleteSupport(propertyId);
        setProperties((prev) => prev.filter((item) => item.id !== propertyId));
        Swal.fire(
          "Deleted!",
          "The Support has been deleted successfully.",
          "success"
        );
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const handleSearchClick = () => {
    setSearchedTerm(searchTerm);
  };

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const totalPages = Math.ceil(totalCount / entriesPerPage);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  const handleDownloadReport = async () => {
    const formData = {
      pageSize: totalCount,
      pageNo: 1,
      fromDate,
      toDate,
      sParam: searchedTerm,
      supportStatus: supportStatus,
      typeOfSupport: selectedProduct,
    };

    try {
      setDownloadBtnDisable(true);
      const response = await paginationSupports(formData);
      const { data } = response;
      const rentReports = data.result;
      setDownloadBtnDisable(false);

      const excelData = rentReports.map((item, index) => ({
        "#": index + 1,
        "User Name": item.userName,
        Tenants: item.userName,
        "Type Of Support": item.typeOfSupport,
        SupportStatus: item.supportStatus,
        Description: item.description,
        "Phone No": item.phoneNo,
        "Caretaker Name": item.caretakerName,
        "Caretaker Contact": item.caretakerContact,
        "Supervisor Name": item.supervisorName,
        "Supervisor Contact": item.supervisorContact,
        "Property Name": item.propertyName,
        "Flat No": item.flatNo,
        "Bhk Type": item.bhkType,
        Furnished: item.furnished,
        Attachment: item.attachment,
        CreatedBy: item.createdBy,
        UpdatedBy: item.updatedBy,
        "Created On": formatDate(item.createdOn),
        "Updated On": formatDate(item.updatedOn),
        TotalCount: item.totalCount,
        "Support Activities": item.supportActivities,
      }));

      const worksheet = XLSX.utils.json_to_sheet(excelData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Rent Report");

      XLSX.writeFile(workbook, "Rent_Reports.xlsx");
    } catch (error) {
      handleErrors(error);
    }
  };

  return (
    <>
      <style>
        {`
                    .ri-pencil-fill:before {
                      display:none;
                    }
                `}
      </style>
      {pageAccessDetails.viewAccess ? (
        <div className="row">
          <div className="col-xxl-12">
            <div className="card mt-xxl-n5">
              <div className="card-header responsive-filter-type justify-content-between">
                <h5 className="mb-sm-2 mt-sm-2">Manage Support</h5>
                {pageAccessDetails.downloadAccess ? (
                  <button
                    className="btn btn-download"
                    onClick={handleDownloadReport}
                    disabled={downloadBtnDisable}
                  >
                    <img
                      src={allImages.Excel}
                      alt="Rent report excel icon"
                      width="24px"
                    />{" "}
                    <span>
                      {downloadBtnDisable ? "Exporting..." : "Export To Excel"}
                    </span>
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="card-body manage-amenity-master-card-body">
                <div className="row responsive-filter-type mb-3">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="entries-dropdownx">
                        <label
                          htmlFor="entriesPerPage"
                          className="form-label me-2"
                        >
                          Show entries:
                        </label>
                        <select
                          className="form-select"
                          id="entriesPerPage"
                          value={entriesPerPage}
                          onChange={handleEntriesPerPageChange}
                        >
                          <option value="30">30</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="date-filters">
                        <label htmlFor="fromDate" className="form-label me-2">
                          From Date:
                        </label>
                        <Flatpickr
                          id="fromDate"
                          className="form-control"
                          placeholder="Select From Date"
                          value={fromDate}
                          onChange={([date]) => setFromDate(date)}
                          options={{
                            dateFormat: "Y-m-d",
                            monthSelectorType: "static",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="date-filters">
                        <label htmlFor="toDate" className="form-label me-2">
                          To Date:
                        </label>
                        <Flatpickr
                          id="toDate"
                          className="form-control"
                          placeholder="Select To Date"
                          value={toDate}
                          onChange={([date]) => setToDate(date)}
                          options={{
                            dateFormat: "Y-m-d",
                            monthSelectorType: "static",
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <label htmlFor="search" className="form-label me-2">
                        Support Status:
                      </label>
                      <select
                        className="form-select"
                        value={supportStatus}
                        onChange={(e) => setsupportStatus(e.target.value)}
                      >
                        <option value="">Select..</option>
                        <option value="Created">Created</option>
                        <option value="InProgress">InProgress</option>
                        <option value="Closed">Closed</option>
                        <option value="Resolved">Resolved</option>
                        <option value="Opened">Opened</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <div className="mb-3">
                        <label htmlFor="productName" className="form-label">
                          Type of Support{" "}
                        </label>
                        <select
                          value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}
                          className="form-select"
                        >
                          <option value="">Select Support Type</option>
                          <option value="Common area">Common area</option>
                          <option value="Electrical issue">
                            Electrical issue
                          </option>
                          <option value="Flat furnishing">
                            Flat furnishing
                          </option>
                          <option value="Flat renovation">
                            Flat renovation
                          </option>
                          <option value="Furniture issue">
                            Furniture issue
                          </option>
                          <option value="House keeping">House keeping</option>
                          <option value="Lift issue">Lift issue</option>
                          <option value="Offline marketing">
                            Offline marketing
                          </option>
                          <option value="Painting">Painting</option>
                          <option value="Pest control">Pest control</option>
                          <option value="Plumbing issue">Plumbing issue</option>
                          <option value="Porter">Porter</option>
                          <option value="Water issue">Water issue</option>
                          <option value="Water tanker">Water tanker</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="search-input">
                        <label htmlFor="search" className="form-label me-2">
                          Search:
                        </label>
                        <input
                          type="text"
                          id="search"
                          className="form-control"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          placeholder="Search...."
                          ref={searchInputRef}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="pt-2">
                        <button
                          className="btn btn-secondary btn-properties-search"
                          onClick={handleSearchClick}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="table-responsive">
                    <table className="table align-middle table-nowrap table-bordered">
                      <TableHeader
                        columns={[
                          "#",
                          "Ticket ID",
                          "Tenants",
                          "Supervisor",
                          "Property",
                          "Description",
                          "Caretaker",
                          "Created By",
                          "Status",
                          "Action",
                        ]}
                      />
                      <tbody className="manage-amenity-master-table-values">
                        {properties.length > 0 ? (
                          properties.map((item, index) => (
                            <TablesRow
                              key={item.id}
                              rowData={{
                                id:
                                  (currentPage - 1) * entriesPerPage +
                                  index +
                                  1,
                                ticketInfo: (
                                  <>
                                    <div>
                                      <Link
                                        to={`/support/detail/${item.id}`}
                                        style={{ textDecoration: "none" }}
                                      >
                                        {item.ticketId}
                                        <div className="text-dark">
                                          {item.typeOfSupport}
                                        </div>
                                      </Link>
                                    </div>
                                  </>
                                ),
                                tenantsInfo: (
                                  <>
                                    <div>{item.userName}</div>
                                    <div>{item.phoneNo}</div>
                                  </>
                                ),
                                supervisorinfo:
                                  item.reAssignSupervisorName &&
                                  item.reAssignSupervisorContact ? (
                                    <>
                                      <div>{item.reAssignSupervisorName}</div>
                                      <div>
                                        {item.reAssignSupervisorContact}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div>{item.supervisorName}</div>
                                      <div>{item.supervisorContact}</div>
                                    </>
                                  ),
                                caretakerinfo: (
                                  <>
                                    <div>{item.caretakerName}</div>
                                    <div>{item.caretakerContact}</div>
                                  </>
                                ),
                                createdBy: <div>{item.createdBy}</div>,
                                username: item.userName,
                                propertyName: item.propertyName ? (
                                  <>
                                    <span>{item.propertyName || "-"}</span>
                                    <br />
                                    <label>Flat: </label>
                                    <span>{item.flatNo || "-"}</span>
                                    <br />(<span>{item.bhkType || "-"}</span> -
                                    <span>{item.furnished || "-"}</span>)
                                  </>
                                ) : (
                                  "-"
                                ),
                                typeOfSupport: item.typeOfSupport || "-",
                                description: item.description || "-",

                                status: (
                                  <span
                                    className={`badge rounded-pill ${
                                      item.supportStatus === "Created"
                                        ? "badge-soft-primary"
                                        : item.supportStatus === "InProgress"
                                        ? "badge-soft-warning"
                                        : item.supportStatus === "Waiting"
                                        ? "badge-soft-warning"
                                        : item.supportStatus === "Closed"
                                        ? "badge-soft-success"
                                        : "badge-soft-warning"
                                    }`}
                                  >
                                    {item.supportStatus}
                                  </span>
                                ),
                              }}
                              columns={[
                                "id",
                                "ticketInfo",
                                "tenantsInfo",
                                "supervisorinfo",
                                "propertyName",
                                "description",
                                "caretakerinfo",
                                "createdBy",
                                "status",
                              ]}
                              hideIcons={false}
                              showIcons={false}
                              onEdit={() => {
                                navigate(`update/${item.id}`);
                                window.scrollTo({
                                  top: 0,
                                  behavior: "smooth",
                                });
                              }}
                              onDelete={() => handleDelete(item.id)}
                              pageLevelAccessData={pageAccessDetails}
                            />
                          ))
                        ) : (
                          <TableDataStatusError colspan="10" />
                        )}
                      </tbody>
                    </table>
                  </div>
                )}
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                  totalEntries={totalCount}
                  entriesPerPage={entriesPerPage}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
