import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const addPropertyOwner = async (ownerData) => {
  try {
    const formData = new FormData();
    formData.append("FirstName", ownerData.firstName);
    formData.append("LastName", ownerData.lastName);
    formData.append("EmailAddress", ownerData.emailAddress);
    formData.append("DOB", ownerData.DOB);
    formData.append("ContactNo", ownerData.contactNo);
    formData.append("PANNo", ownerData.PANNo);
    formData.append("FatherName", ownerData.FatherName);
    formData.append("CompleteAddress", ownerData.CompleteAddress);
    formData.append("Password", ownerData.owner_password);

    if (ownerData.profileImage) {
      formData.append("ProfileImage", ownerData.profileImage);
    }
    const response = await axiosInstance.post(
      "/onboarding/owner/add",
      formData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to add property owner."
    );
  }
};

export const getPropertyOwners = async () => {
  return await axiosInstance.get("onboarding/owner/all", { headers });
};
export const getOwners = async () => {
  return await axiosInstance.get("onboarding/owner/all", { headers });
};

export const createPassword = async (data) => {
  return await axiosInstance.put("onboarding/owner/change-password", data, {
    headers,
  });
};

export const deletePropertyOwner = async (ownerId) => {
  return await axiosInstance.delete(`onboarding/owner/${ownerId}`, { headers });
};

export const updatePropertyOwner = async (payload) => {
  return await axiosInstance.put("/onboarding/owner/update", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${Cookies.get("accessToken")}`,
    },
  });
};

export const fetchPropertyOwnerData = async (ownerId) => {
  const response = await axiosInstance.get(`onboarding/owner/${ownerId}`, {
    headers,
  });
  return response.data.result;
};

export const fetchPropertyOwnerDatabyGuid = async (ownerGuid) => {
  const response = await axiosInstance.get(
    `onboarding/owner/get/${ownerGuid}`,
    { headers }
  );
  return response.data.result;
};

export const uploadOwnerDocument = async (documentData) => {
  try {
    const formData = new FormData();

    formData.append("OwnerGuid", documentData.OwnerGuid);
    formData.append("DocumentName", documentData.documentName);

    if (documentData.attachment) {
      formData.append("Attachment", documentData.attachment);
    }

    const response = await axiosInstance.post(
      "/onboarding/owner/document",
      formData,
      {
        headers: {
          Authorization: `Bearer ${Cookies.get("accessToken")}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to upload document."
    );
  }
};

export const getPropertyOwnersDocuments = async (ownerGuid) => {
  return await axiosInstance.get(`onboarding/owner/document/all/${ownerGuid}`, {
    headers,
  });
};

export const deletePropertyOwnerDocument = async (documentId) => {
  return await axiosInstance.delete(`onboarding/owner/document/${documentId}`, {
    headers,
  });
};

export const fetchPropertyOwnerDocuments = async (documentId) => {
  const response = await axiosInstance.get(
    `onboarding/owner/document/${documentId}`,
    { headers }
  );
  return response.data.result;
};

export const getPropertyOwnersProperties = async (ownerGuid) => {
  return await axiosInstance.get(
    `onboarding/owner/properties?ownerGuid=${ownerGuid}`,
    { headers }
  );
};

export const AddOwnerBankDetailsService = async (BankData) => {
  const formData = {
    accountName: BankData.accountName,
    accountNo: BankData.accountNo,
    bankName: BankData.bankName,
    ifscCode: BankData.ifscCode,
    ownerGuid: BankData.ownerGuid,
  };
  const response = await axiosInstance.post("onboarding/owner/bank", formData, {
    headers,
  });
  return response.data;
};

export const UpdateOwnerBankDetails = async (BankData) => {
  const response = await axiosInstance.put(
    "onboarding/owner/bank",
    { BankData },
    { headers }
  );
  return response.data;
};

export const getOwnersBankDetails = async (ownerGuid) => {
  return await axiosInstance.get(
    `onboarding/owner/bank?ownerGuid=${ownerGuid}`,
    { headers }
  );
};

export const fetchPropertyOwnerAgreements = async (ownerguid) => {
  const response = await axiosInstance.get(
    `onboarding/owner/agreements/${ownerguid}`,
    { headers }
  );
  return response.data.result;
};

export const createAgreementPreview = async (formData) => {
  const response = await axiosInstance.post(
    "onboarding/owner/agreement-preview",
    formData,
    { headers }
  );
  return response.data;
};

export const SignEStampAgreement = async (agreementId) => {
  const response = await axiosInstance.post(
    `onboarding/owner/estamp-agreement/${agreementId}`,
    { headers }
  );
  return response.data.result;
};
