import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const fetchPropertiesByLocations = async (locationName) => {
  const response = await axiosInstance.post(
    `properties/by-location/?locationName=${locationName}`,
    { headers }
  );
  return response.data.result;
};

export const fetchSWRProperty = async () => {
  const response = await axiosInstance.get("properties/all", { headers });
  return response.data.result;
};

export const addSupervisor = async (formData) => {
  const payload = formData.formData;
  const response = await axiosInstance.post(
    "properties/supervisor/assign",
    payload,
    { headers }
  );
  return response.data.result;
};

export const fetchAssignedSupervisors = async () => {
  const response = await axiosInstance.get("properties/supervisor/assign", {
    headers,
  });
  return response.data;
};

export const deleteAssignedSupervisors = async (assignedId) => {
  await axiosInstance.delete(`properties/supervisor/assign/${assignedId}`, {
    headers,
  });
};

export const updateAssignedSupervisor = async (payload) => {
  const response = await axiosInstance.put(
    "properties/supervisor/assign",
    payload,
    { headers }
  );
  return response.data;
};

export const fetchAssignedSupervisorById = async (id) => {
  const response = await axiosInstance.get(
    `properties/supervisor/assign/${id}`,
    { headers }
  );
  return response.data;
};

export const fetchSupervisorsList = async () => {
  const response = await axiosInstance.get("auth/supervisors", { headers });
  return response.data.result;
};

export const fetchPropertiesBySupervisor = async (supervisorGuid) => {
  try {
    const response = await axiosInstance.get(
      `properties/supervisor/assign/single/${supervisorGuid}`,
      { headers }
    );
    return response.data.result;
  } catch (error) {
    console.error("Error fetching properties by supervisor:", error);
    throw error;
  }
};

export const reAssignSupervisor = async (supportId, supervisorGuid) => {
  try {
    const response = await axiosInstance.post(
      `/support/re-assign?supportId=${supportId}&supervisorGuid=${supervisorGuid}`,
      {},
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Error re-assigning supervisor:", error);
    throw error;
  }
};
