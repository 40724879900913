import React from "react";
import { ToastContainer } from "react-toastify";
import { ManageRentConsolidated } from "../components/Reports/ManageRentConsolidated";
export const ManageReportConsolidated = () => {
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
          />
          <ManageRentConsolidated />
        </div>
      </div>
    </div>
  );
};
