import React, { useState } from "react";
import TableHeader from "../Common/TableComponent/TableHeader";
import { TableDataStatusError } from "../Common/OtherElements/TableDataStatusError";
import { Link } from "react-router-dom";
import { PaymentUpdateModal } from "./PaymentUpdateModal";
import {
  UpdateRentAmount,
  verifyPayment,
} from "../../services/orderManagementService";
import Swal from "sweetalert2";
import { confirmVerified } from "../Common/OtherElements/confirmDeleteClone";
import { handleErrors } from "../../utils/errorHandler";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Button, Form } from "react-bootstrap";

export const RentDetails = ({ customerData, handleFetchData }) => {
  const [modalAddPaymentVisible, setModalAddPaymentVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState("");
  const [rentAmount, setRentAmount] = useState("");
  const [discount, setDiscount] = useState("");

  const handleAddPaymentOpenModal = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setModalAddPaymentVisible(true);
  };

  const handleAddPaymentCloseModal = () => {
    setModalAddPaymentVisible(false);
    setSelectedPaymentId(null);
  };

  const handleOpenModal = (paymentId, currentRentAmount) => {
    setSelectedPaymentId(paymentId);
    setRentAmount(currentRentAmount);
    setDiscount("");
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedPaymentId(null);
  };

  const handleSave = async () => {
    try {
      const discountValue = discount || 0;
      const updatedAmount = rentAmount - discountValue;
      if (updatedAmount < 0) {
        toast.error("Discount cannot be more than the rent amount");
      } else {
        await UpdateRentAmount(selectedPaymentId, updatedAmount, discountValue);
        toast.success("Rent Amount Updated Successfully");
        handleFetchData();
        handleCloseModal();
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const rentdetails = customerData?.oRent || [];
  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const handlePaymentSuccess = () => {
    handleFetchData();
  };

  const handleVerifyPayment = async (paymentId, vStatus) => {
    try {
      let paymentType = "";

      if (customerData?.oRent?.some((item) => item.id === paymentId)) {
        paymentType = "Rent";
      } else if (
        customerData?.oUtility?.some((item) => item.id === paymentId)
      ) {
        paymentType = "Utility";
      } else if (
        customerData?.oMaintenance?.some((item) => item.id === paymentId)
      ) {
        paymentType = "Maintenance";
      } else if (
        customerData?.oOtherItems?.some((item) => item.id === paymentId)
      ) {
        paymentType = "Other";
      }

      if (!paymentType) {
        toast.error("Invalid payment type.");
        return;
      }

      await verifyPayment({ paymentId, paymentType, vStatus });
      toast.success(`Payment marked as ${vStatus}`);
      handleFetchData();
    } catch (error) {
      toast.error("Failed to verify payment.");
    }
  };

  const handleOpenConfirmationModal = async (paymentId, vStatus) => {
    const confirmed = await confirmVerified(`${vStatus}`);
    if (confirmed) {
      await handleVerifyPayment(paymentId, vStatus);
      Swal.fire(
        `${vStatus}!`,
        `The payment has been marked as ${vStatus}.`,
        "success"
      );
    }
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
    setSelectedPaymentId(null);
  };

  const handleConfirmVerification = async () => {
    await handleVerifyPayment(selectedPaymentId, "Verified");
    setConfirmationModalVisible(false);
  };

  return (
    <div className="card mt-xxl-n5">
      <div className="card-header">
        <h5 className="mb-sm-1 mt-sm-1">Rent Details</h5>
      </div>
      <div className="table-responsive">
        <table className="table table-nowrap align-middle table-bordered">
          <TableHeader
            columns={[
              "#",
              "From Date",
              "To Date",
              "Rent Amount",
              "Rent for this month",
              "Due Amount",
              "ActualRent Amount",
              "CurrentRent Amount",
              "Difference Amount",
              "Difference Without Tax",
              "Tax",
              "Payment Id",
              "Payment Status",
              "Paid On",
              "Add Payment",
            ]}
          />
          <tbody className="manage-page-group-table-values">
            {rentdetails.length === 0 ? (
              <TableDataStatusError colspan="12" />
            ) : (
              rentdetails.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{formatDate(item.fromDate)}</td>
                  <td>{formatDate(item.toDate)}</td>
                  <td>
                    <div>
                      Rs. {item.rentAmount}{" "}
                      <i
                        className="ri-pencil-fill"
                        style={{ cursor: "pointer", color: "#22186f" }}
                        onClick={() =>
                          handleOpenModal(item.orderGuid, item.rentAmount)
                        }
                      ></i>
                    </div>
                  </td>
                  <td>Rs. {item.currentRentAmount}</td>
                  <td>Rs. {item.dueAmount}</td>
                  <td>Rs. {item.actualRent}</td>
                  <td>Rs. {item.currentRentAmount}</td>
                  <td>Rs. {item.differenceAmount}</td>
                  <td>Rs. {item.differenceWithoutTax}</td>
                  <td>Rs. {item.taxAmount}</td>
                  <td>{item.paymentId}</td>
                  <td>
                    {item.paymentStatus === "Initiated" && (
                      <span className="badge rounded-pill badge-soft-primary me-2">
                        Initiated
                      </span>
                    )}
                    {item.paymentStatus === "Success" && (
                      <span className="badge rounded-pill badge-soft-success me-2">
                        Success
                      </span>
                    )}
                    {item.paymentStatus === "Failed" && (
                      <span className="badge rounded-pill badge-soft-danger me-2">
                        Failed
                      </span>
                    )}
                    {item.paymentStatus === "Pending" && (
                      <span className="badge rounded-pill badge-soft-warning me-2">
                        Pending
                      </span>
                    )}
                    {item.vStatus === "Not Verified" && (
                      <span className="badge badge-outline-warning">
                        Not Verified
                      </span>
                    )}
                    {item.vStatus === "Verified" && (
                      <span className="badge badge-outline-secondary">
                        Verified
                      </span>
                    )}
                  </td>
                  <td>{formatDate(item.paidOn)}</td>
                  <td>
                    {item.paymentStatus === "Success" ? (
                      <Button
                        variant={
                          item.vStatus === "Verified" ? "danger" : "primary"
                        }
                        className="badge"
                        style={{ textDecoration: "none", color: "#fff" }}
                        onClick={() =>
                          handleOpenConfirmationModal(
                            item.id,
                            item.vStatus === "Verified"
                              ? "Not Verified"
                              : "Verified"
                          )
                        }
                      >
                        {item.vStatus === "Verified"
                          ? "Unverify Payment"
                          : "Verify Payment"}
                      </Button>
                    ) : (
                      <span
                        className="badge text-bg-danger"
                        style={{ fontSize: "11px" }}
                      >
                        <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#fff" }}
                          onClick={() =>
                            handleAddPaymentOpenModal(
                              item.orderGuid,
                              item.rentAmount
                            )
                          }
                        >
                          Add Payment
                        </Link>
                      </span>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <PaymentUpdateModal
        show={modalAddPaymentVisible}
        onClose={handleAddPaymentCloseModal}
        orderId={selectedPaymentId}
        pageIdentify="rent"
        onPaymentSuccess={handlePaymentSuccess}
      />

      <Modal
        show={confirmationModalVisible}
        onHide={handleCloseConfirmationModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Payment Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to verify the payment?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmationModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmVerification}>
            Yes, Verify
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Rent Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Rent Amount</Form.Label>
              <Form.Control
                type="number"
                value={rentAmount}
                onChange={(e) => {
                  const value = e.target.value;
                  setRentAmount(value === "" ? null : Number(value));
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Discount</Form.Label>
              <Form.Control
                type="number"
                value={discount}
                onChange={(e) => {
                  const value = e.target.value;
                  setDiscount(value === "" ? null : Number(value));
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Calculated Amount (Rent Amount - Discount)
              </Form.Label>
              <Form.Control
                type="number"
                value={rentAmount - discount}
                readOnly
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSave}>
            Update
          </Button>
          <Button variant="danger" onClick={handleCloseModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
