import axiosInstance from "../Interceptors/axiosInstance.jsx";
import Cookies from "js-cookie";

const headers = {
  accept: "*/*",
  Authorization: `Bearer ${Cookies.get("accessToken")}`,
  "Content-Type": "application/json",
};

export const addorderManagement = async (formData) => {
  const response = await axiosInstance.post("onboarding/order", formData, {
    headers,
  });
  return response.data.result;
};

export const orderViewDetails = async (orderId) => {
  const response = await axiosInstance.get(
    `onboarding/order-detail/?orderGuid=${orderId}`,
    { headers }
  );
  return response;
};

export const paginationOrders = async (formData) => {
  const response = await axiosInstance.post("onboarding/orders", formData, {
    headers,
  });
  return response;
};

export const updateOrderStatus = async (orderGuid, orderStatus) => {
  return await axiosInstance.put(
    `onboarding/order-status?orderGuid=${orderGuid}&orderStatus=${orderStatus}`,
    { headers }
  );
};

export const addRentPayment = async (formData) => {
  const response = await axiosInstance.put(
    "onboarding/rent/payment-status",
    formData,
    { headers }
  );
  return response.data.result;
};

export const addUtilityPayment = async (formData) => {
  const response = await axiosInstance.put(
    "onboarding/utility/payment-status",
    formData,
    { headers }
  );
  return response.data.result;
};

export const addMaintenancePayment = async (formData) => {
  const response = await axiosInstance.put(
    "onboarding/maintenance/payment-status",
    formData,
    { headers }
  );
  return response.data.result;
};

export const addOtherPayment = async (formData) => {
  const response = await axiosInstance.put(
    "onboarding/other/payment-status",
    formData,
    { headers }
  );
  return response.data.result;
};

export const Extendorder = async (formData) => {
  const response = await axiosInstance.post(
    "onboarding/order-extension",
    formData,
    { headers }
  );
  return response.data.result;
};

export const MoveOut = async (orderGuid, moveoutDate) => {
  return await axiosInstance.put(
    `onboarding/order-moved-out?orderGuid=${orderGuid}&moveOutDate=${moveoutDate}`,
    { headers }
  );
};

export const UpdateRentAmount = async (
  orderGuid,
  updatedAmount,
  discountValue
) => {
  const response = await axiosInstance.put(
    `onboarding/rent/change-amount?orderGuid=${orderGuid}&rentAmount=${updatedAmount}&discount=${discountValue}`,
    { headers }
  );
  return response.data.result;
};

export const UpdateutilityAmount = async (
  orderGuid,
  utlityAmount,
  utilityId,
  discount
) => {
  const response = await axiosInstance.put(
    `onboarding/utility/change-amount?orderGuid=${orderGuid}&utilityAmount=${utlityAmount}&utilityId=${utilityId}&discount=${discount}`,
    { headers }
  );
  return response.data.result;
};
export const UpdateotherAmount = async (
  orderGuid,
  otherAmount,
  otherId,
  discount
) => {
  const response = await axiosInstance.put(
    `onboarding/other/change-amount?orderGuid=${orderGuid}&otherAmount=${otherAmount}&otherId=${otherId}&discount=${discount}`,
    { headers }
  );
  return response.data.result;
};

export const verifyPayment = async ({ paymentType, paymentId, vStatus }) => {
  try {
    const response = await axiosInstance.put(
      `onboarding/payment/verify?paymentType=${paymentType}&paymentId=${paymentId}&vStatus=${vStatus}`,
      {},
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying payment:", error);
    throw error;
  }
};
export const verifyPaymentstatus = async ({
  paymentType,
  paymentId,
  pvStatus,
}) => {
  try {
    const response = await axiosInstance.put(
      `onboarding/payment/verify?paymentType=${paymentType}&paymentId=${paymentId}&vStatus=${pvStatus}`,
      {},
      { headers }
    );
    return response.data;
  } catch (error) {
    console.error("Error verifying payment:", error);
    throw error;
  }
};
