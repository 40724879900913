import React, { useState } from "react";
import TableHeader from "../Common/TableComponent/TableHeader";
import { TableDataStatusError } from "../Common/OtherElements/TableDataStatusError";
import { Link } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import {
  UpdateutilityAmount,
  verifyPayment,
} from "../../services/orderManagementService";
import { handleErrors } from "../../utils/errorHandler";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PaymentUpdateModal } from "./PaymentUpdateModal";
import Swal from "sweetalert2";
import { confirmVerified } from "../Common/OtherElements/confirmDeleteClone";

export const UtilityDetails = ({ customerData, handleFetchData }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalAddPaymentVisible, setModalAddPaymentVisible] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedPaymentId, setSelectedPaymentId] = useState("");
  const [editedAmount, setEditedAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const [netAmount, setNetAmount] = useState("");
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);

  const utilityDetails = customerData?.oUtility || [];

  console.log("utlityDetauls", utilityDetails);

  const formatDate = (dateString) => {
    if (!dateString) return "-";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const handleAddPaymentOpenModal = (paymentId) => {
    setSelectedPaymentId(paymentId);
    setModalAddPaymentVisible(true);
  };

  const handleAddPaymentCloseModal = () => {
    setModalAddPaymentVisible(false);
    setSelectedPaymentId(null);
  };

  const handleOpenModal = (item) => {
    setSelectedRowData(item);
    setEditedAmount(item.utilityAmount);
    setDiscount("");
    setNetAmount(item.utilityAmount);
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedRowData({});
  };

  console.log("selected payment id", selectedPaymentId);

  const handleSaveClick = async () => {
    try {
      const calculatedDiscount = discount || 0;
      if (netAmount < 0) {
        toast.error("Discount cannot be more than the utility amount");
      } else {
        await UpdateutilityAmount(
          selectedRowData.orderGuid,
          netAmount,
          selectedRowData.id,
          calculatedDiscount
        );
        handleFetchData();
        toast.success("Utility details updated successfully");
        handleCloseModal();
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleAmountChange = (value) => {
    setEditedAmount(value === "" ? null : Number(value));
    setNetAmount(value - (discount || 0));
  };

  const handleDiscountChange = (value) => {
    setDiscount(value === "" ? null : Number(value));
    setNetAmount((editedAmount || 0) - value);
  };

  const handlePaymentSuccess = () => {
    handleFetchData();
  };
  const handleVerifyPayment = async (paymentId, vStatus) => {
    try {
      let paymentType = "";

      if (customerData?.oRent?.some((item) => item.id === paymentId)) {
        paymentType = "Rent";
      } else if (
        customerData?.oUtility?.some((item) => item.id === paymentId)
      ) {
        paymentType = "Utility";
      } else if (
        customerData?.oMaintenance?.some((item) => item.id === paymentId)
      ) {
        paymentType = "Maintenance";
      } else if (
        customerData?.oOtherItems?.some((item) => item.id === paymentId)
      ) {
        paymentType = "Other";
      }

      if (!paymentType) {
        toast.error("Invalid payment type.");
        return;
      }

      await verifyPayment({ paymentId, paymentType, vStatus });
      toast.success(`Payment marked as ${vStatus}`);
      handleFetchData();
    } catch (error) {
      toast.error("Failed to verify payment.");
    }
  };
  const handleOpenConfirmationModal = async (paymentId, vStatus) => {
    const confirmed = await confirmVerified(`${vStatus}`);
    if (confirmed) {
      await handleVerifyPayment(paymentId, vStatus);
      Swal.fire(
        `${vStatus}!`,
        `The payment has been marked as ${vStatus}.`,
        "success"
      );
    }
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
    setSelectedPaymentId(null);
  };

  const handleConfirmVerification = async () => {
    await handleVerifyPayment(selectedPaymentId, "Verified");
    setConfirmationModalVisible(false);
  };

  return (
    <div className="card mt-xxl-n5">
      <div className="card-header">
        <h5 className="mb-sm-1 mt-sm-1">Utility Details</h5>
      </div>
      <div className="table-responsive">
        <table className="table table-nowrap align-middle table-bordered">
          <TableHeader
            columns={[
              "#",
              "From Date",
              "To Date",
              "Utility Type",
              "Utility Amount",
              "Tax Amount",
              "Utility for this month",
              "Payment Id",
              "Payment Status",
              "Paid On",
              "Updated On",
              "Add Payment",
            ]}
          />
          <tbody className="manage-page-group-table-values">
            {utilityDetails.length === 0 ? (
              <TableDataStatusError colspan="12" />
            ) : (
              utilityDetails.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{formatDate(item.fromDate)}</td>
                  <td>{formatDate(item.toDate)}</td>
                  <td>{item.utilityType}</td>
                  <td>
                    Rs. {item.utilityAmount}{" "}
                    <i
                      className="ri-pencil-fill"
                      style={{ cursor: "pointer", color: "#22186f" }}
                      onClick={() => handleOpenModal(item)}
                    />
                  </td>
                  <td>Rs. {item.taxAmount}</td>
                  <td>Rs. {item.totalAmount}</td>
                  <td>{item.paymentId}</td>
                  <td>
                    {item.paymentStatus === "Initiated" && (
                      <span className="badge rounded-pill badge-soft-primary me-2">
                        Initiated
                      </span>
                    )}
                    {item.paymentStatus === "Success" && (
                      <span className="badge rounded-pill badge-soft-success me-2">
                        Success
                      </span>
                    )}
                    {item.paymentStatus === "Failed" && (
                      <span className="badge rounded-pill badge-soft-danger me-2">
                        Failed
                      </span>
                    )}
                    {item.paymentStatus === "Pending" && (
                      <span className="badge rounded-pill badge-soft-warning me-2">
                        Pending
                      </span>
                    )}

                    {item.vStatus === "Not Verified" && (
                      <span className="badge badge-outline-warning">
                        Not Verified
                      </span>
                    )}
                    {item.vStatus === "Verified" && (
                      <span className="badge badge-outline-secondary">
                        Verified
                      </span>
                    )}
                  </td>
                  <td>{formatDate(item.paidOn)}</td>
                  <td>{formatDate(item.updatedOn)}</td>
                  <td>
                    {item.paymentStatus === "Success" ? (
                      <Button
                        variant={
                          item.vStatus === "Verified" ? "danger" : "primary"
                        }
                        className="badge"
                        style={{ textDecoration: "none", color: "#fff" }}
                        onClick={() =>
                          handleOpenConfirmationModal(
                            item.id,
                            item.vStatus === "Verified"
                              ? "Not Verified"
                              : "Verified"
                          )
                        }
                      >
                        {item.vStatus === "Verified"
                          ? "Unverify Payment"
                          : "Verify Payment"}
                      </Button>
                    ) : (
                      <span
                        className="badge text-bg-danger"
                        style={{ fontSize: "11px" }}
                      >
                        <Link
                          to="#"
                          style={{ textDecoration: "none", color: "#fff" }}
                          onClick={() =>
                            handleAddPaymentOpenModal(
                              item.orderGuid,
                              item.rentAmount
                            )
                          }
                        >
                          Add Payment
                        </Link>
                      </span>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      <PaymentUpdateModal
        show={modalAddPaymentVisible}
        onClose={handleAddPaymentCloseModal}
        orderId={selectedPaymentId}
        pageIdentify="utility"
        onPaymentSuccess={handlePaymentSuccess}
      />
      <Modal
        show={confirmationModalVisible}
        onHide={handleCloseConfirmationModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Payment Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to verify the payment?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmationModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmVerification}>
            Yes, Verify
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Utility Amount</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Utility Amount</Form.Label>
              <Form.Control
                type="number"
                value={editedAmount}
                onChange={(e) => handleAmountChange(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Discount</Form.Label>
              <Form.Control
                type="number"
                value={discount}
                onChange={(e) => handleDiscountChange(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                Calculated Amount (Utlity Amount - Discount)
              </Form.Label>
              <Form.Control type="number" value={netAmount} readOnly />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleSaveClick}>
            Update
          </Button>
          <Button variant="danger" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
